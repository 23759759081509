@font-face {
    font-family: CeraPro-Regular;
    src: url('/assets/themes/ernestto/fonts/CeraProRegular.otf') format("opentype");
}

@font-face {
    font-family: CeraPro-Bold;
    src: url('/assets/themes/ernestto/fonts/CeraProBold.otf') format("opentype");
}

@font-face {
    font-family: CeraPro-Black;
    src: url('/assets/themes/ernestto/fonts/CeraProBlack.otf') format("opentype");
}

@font-face {
    font-family: CeraPro-Medium;
    src: url('/assets/themes/ernestto/fonts/CeraProMedium.otf') format("opentype");
}

@font-face {
    font-family: HermanoAlto-Round;
    src: url('/assets/themes/ernestto/fonts/HermanoAltoRound.ttf') format("opentype");
}

@font-face {
    font-family: HermanoAlto-Stamp;
    src: url('/assets/themes/ernestto/fonts/HermanoAltoStamp.ttf') format("opentype");
}
