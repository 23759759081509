$font_size_h1: 48px;
$font_size_h1_mobile: 24px;
$font_size_super: 36px;
$font_size_ultra: 128px;
$font_size_super_mobile: 52px;
$font_size_super_card: 80px;
$font_size_h2: 30px;
$font_size_h2_mobile: 20px;
$font_size_h3: 28px;
$font_size_h3_mobile: 16px;
$font_size_large: 20px;
$font_size_large_mobile: 14px;
$font_size_medium: 18px;
$font_size_medium_mobile: 12px;
$font_size_normal: 14px;
$font_size_small: 12px;
$font_size_very_small: 10px;
$font_letter_spacing: -0.71px;
$font_header_letter_spacing: -0.43px;
$font_row_letter_spacing: -0.64px;
$font_letter_spacing_wider: -0.2px;
$font_password_letter_spacing: 3px;

$border_radius: 10px;
$border_standard_radius: 3px;
$border_standard_field_radius: 5px;
$border_thickness_thin: 1px;
$border_thickness: 2px;
$border_thickness_active: 3px;
$default_margin: 8px;
$default_padding: 8px;
$default_line_height: 24px;
$row_line_height: 30px;
$header_line_height: 16px;
$heading_line_height: 24px;
$heading_line_height_mobile: 18px;
$super_line_height: 52px;
$main_layout_padding: 28px;
$main_layout_padding_mobile: 18px;
$large_layout_padding: 85px;
