:root {
    --surface-a: #ffffff;
    --surface-b: #f3f2f7;
    --surface-c: #f7f6fb;
    --surface-d: #f7f6fb;
    --surface-e: #ffffff;
    --surface-f: #ffffff;
    --text-color: #383097;
    --text-color-secondary: #7267b4;
    --primary-color: #383097;
    --primary-color-text: #ffffff;
    --font-family: CeraPro-Regular;
    --surface-0: #ffffff;
    --surface-50: #f1f3f5;
    --surface-100: #e2e6eb;
    --surface-200: #c6cdd7;
    --surface-300: #a9b4c2;
    --surface-400: #8d9bae;
    --surface-500: #70829a;
    --surface-600: #5a687b;
    --surface-700: #434e5c;
    --surface-800: #2d343e;
    --surface-900: #161a1f;
    --gray-50: #f1f3f5;
    --gray-100: #e2e6eb;
    --gray-200: #c6cdd7;
    --gray-300: #a9b4c2;
    --gray-400: #8d9bae;
    --gray-500: #70829a;
    --gray-600: #5a687b;
    --gray-700: #434e5c;
    --gray-800: #2d343e;
    --gray-900: #161a1f;
    --content-padding: 1rem;
    --inline-spacing: 0.5rem;
    --border-radius: 6px;
    --surface-ground: #f5f5f5;
    --surface-section: #ffffff;
    --surface-card: #ffffff;
    --surface-overlay: #ffffff;
    --surface-border: #ebebeb;
    --surface-hover: #edf0fA;
    --maskbg: rgba(0, 0, 0, 0.4);
    --focus-ring: 0 0 0 0.1rem #a6a1e1
}

* {
    box-sizing: border-box
}

.p-component {
    font-family: CeraPro-Regular;
    font-size: 1rem;
    font-weight: normal
}

.p-component-overlay {
    background-color: rgba(0, 0, 0, .4);
    transition-duration: .3s
}

.p-disabled, .p-component:disabled {
    opacity: .6
}

.p-error {
    color: #f88c79
}

.p-text-secondary {
    color: #7267b4
}

.pi {
    font-size: 1rem
}

.p-link {
    font-size: 1rem;
    font-family: CeraPro-Regular;
    border-radius: 6px
}

.p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-component-overlay-enter {
    animation: p-component-overlay-enter-animation 150ms forwards
}

.p-component-overlay-leave {
    animation: p-component-overlay-leave-animation 150ms forwards
}

@keyframes p-component-overlay-enter-animation {
    from {
        background-color: transparent
    }
    to {
        background-color: var(--maskbg)
    }
}

@keyframes p-component-overlay-leave-animation {
    from {
        background-color: var(--maskbg)
    }
    to {
        background-color: transparent
    }
}

:root {
    --blue-50: #f6fbfd;
    --blue-100: #d6eaf5;
    --blue-200: #b5d9ed;
    --blue-300: #95c8e4;
    --blue-400: #74b7dc;
    --blue-500: #54a6d4;
    --blue-600: #478db4;
    --blue-700: #3b7494;
    --blue-800: #2e5b75;
    --blue-900: #224255;
    --green-50: #f9fbf4;
    --green-100: #e3eccc;
    --green-200: #cddca4;
    --green-300: #b7cd7c;
    --green-400: #a1bd54;
    --green-500: #8bae2c;
    --green-600: #769425;
    --green-700: #617a1f;
    --green-800: #4c6018;
    --green-900: #384612;
    --yellow-50: #fffdf4;
    --yellow-100: #fff4cc;
    --yellow-200: #ffeba3;
    --yellow-300: #ffe27b;
    --yellow-400: #ffd952;
    --yellow-500: #ffd02a;
    --yellow-600: #d9b124;
    --yellow-700: #b3921d;
    --yellow-800: #8c7217;
    --yellow-900: #665311;
    --cyan-50: #f5fafc;
    --cyan-100: #cfe9f3;
    --cyan-200: #a8d8e9;
    --cyan-300: #82c7df;
    --cyan-400: #5bb5d6;
    --cyan-500: #35a4cc;
    --cyan-600: #2d8bad;
    --cyan-700: #25738f;
    --cyan-800: #1d5a70;
    --cyan-900: #154252;
    --pink-50: #fdf6f8;
    --pink-100: #f5d6dd;
    --pink-200: #edb5c2;
    --pink-300: #e495a8;
    --pink-400: #dc748d;
    --pink-500: #d45472;
    --pink-600: #b44761;
    --pink-700: #943b50;
    --pink-800: #752e3f;
    --pink-900: #55222e;
    --indigo-50: #f6f8fd;
    --indigo-100: #d6ddf5;
    --indigo-200: #b5c2ed;
    --indigo-300: #95a8e4;
    --indigo-400: #748ddc;
    --indigo-500: #5472d4;
    --indigo-600: #4761b4;
    --indigo-700: #3b5094;
    --indigo-800: #2e3f75;
    --indigo-900: #222e55;
    --teal-50: #f5fcfa;
    --teal-100: #d1f0e8;
    --teal-200: #ace3d6;
    --teal-300: #88d7c4;
    --teal-400: #63cbb2;
    --teal-500: #3fbfa0;
    --teal-600: #36a288;
    --teal-700: #2c8670;
    --teal-800: #236958;
    --teal-900: #194c40;
    --orange-50: #fffaf4;
    --orange-100: #ffe5cc;
    --orange-200: #ffd0a3;
    --orange-300: #ffbb7b;
    --orange-400: #ffa752;
    --orange-500: #ff922a;
    --orange-600: #d97c24;
    --orange-700: #b3661d;
    --orange-800: #8c5017;
    --orange-900: #663a11;
    --bluegray-50: #f9fafb;
    --bluegray-100: #e1e5ea;
    --bluegray-200: #c9d0d9;
    --bluegray-300: #b1bbc8;
    --bluegray-400: #99a6b7;
    --bluegray-500: #8191a6;
    --bluegray-600: #6e7b8d;
    --bluegray-700: #5a6674;
    --bluegray-800: #47505b;
    --bluegray-900: #343a42;
    --purple-50: #f8f6fd;
    --purple-100: #ded6f5;
    --purple-200: #c4b5ed;
    --purple-300: #aa95e4;
    --purple-400: #9074dc;
    --purple-500: #7654d4;
    --purple-600: #6447b4;
    --purple-700: #533b94;
    --purple-800: #412e75;
    --purple-900: #2f2255;
    --red-50: #fff7f6;
    --red-100: #ffd8d2;
    --red-200: #ffb9af;
    --red-300: #ff9b8b;
    --red-400: #ff7c68;
    --red-500: #ff5d44;
    --red-600: #d94f3a;
    --red-700: #b34130;
    --red-800: #8c3325;
    --red-900: #66251b;
    --primary-50: #f5f5fa;
    --primary-100: #cfcde6;
    --primary-200: #a9a6d2;
    --primary-300: #847fbf;
    --primary-400: #5e57ab;
    --primary-500: #383097;
    --primary-600: #302980;
    --primary-700: #27226a;
    --primary-800: #1f1a53;
    --primary-900: #16133c
}

.p-autocomplete .p-autocomplete-loader {
    right: .75rem
}

.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
    right: 3.607rem
}

.p-autocomplete .p-autocomplete-multiple-container {
    padding: .25rem .75rem
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
    border-color: #f4f3f8
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1;
    border-color: #f2f1f6
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
    padding: .25rem 0
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-family: CeraPro-Regular;
    font-size: 1rem;
    color: #383097;
    padding: 0;
    margin: 0
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
    padding: .25rem .75rem;
    margin-right: .5rem;
    background: #383097;
    color: #fff;
    border-radius: 6px
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
    margin-left: .5rem
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
    border-color: #f88c79
}

.p-autocomplete-panel {
    background: #fff;
    color: #383097;
    border: 0 none;
    border-radius: 6px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.p-autocomplete-panel .p-autocomplete-items:not(.p-autocomplete-virtualscroll) {
    padding: .5rem .5rem
}

.p-autocomplete-panel .p-autocomplete-items.p-autocomplete-virtualscroll .cdk-virtual-scroll-content-wrapper {
    padding: .5rem .5rem
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
    margin: 0 0 4px 0;
    padding: .5rem 1rem;
    border: 0 none;
    color: #383097;
    background: transparent;
    transition: box-shadow .3s;
    border-radius: 6px
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
    color: #383097;
    background: #f7f6fb
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
    color: #fff;
    background: #383097
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-empty-message {
    padding: .5rem 1rem;
    color: #383097;
    background: transparent
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
    margin: 0;
    padding: .75rem 1rem;
    color: #383097;
    background: #fff;
    font-weight: 100
}

p-autocomplete.p-autocomplete-clearable .p-inputtext {
    padding-right: 2.5rem
}

p-autocomplete.p-autocomplete-clearable .p-autocomplete-clear-icon {
    color: #7267b4;
    right: .75rem
}

p-autocomplete.p-autocomplete-clearable.p-autocomplete-dd .p-autocomplete-clear-icon {
    color: #7267b4;
    right: 3.607rem
}

p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
    border-color: #f88c79
}

.p-datepicker {
    padding: .5rem;
    background: #fff;
    color: #383097;
    border: 2px solid #eeedf2;
    border-radius: 6px
}

.p-datepicker:not(.p-datepicker-inline) {
    background: #fff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
    background: #fff
}

.p-datepicker .p-datepicker-header {
    padding: .5rem;
    color: #383097;
    background: #fff;
    font-weight: 100;
    margin: 0;
    border-bottom: 1px solid #f7f6fb;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px
}

.p-datepicker .p-datepicker-header .p-datepicker-prev, .p-datepicker .p-datepicker-header .p-datepicker-next {
    width: 2rem;
    height: 2rem;
    color: #7267b4;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color .3s, color .3s, box-shadow .3s
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover, .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
    color: #383097;
    border-color: transparent;
    background: #f7f6fb
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:focus, .p-datepicker .p-datepicker-header .p-datepicker-next:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
    line-height: 2rem
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year, .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    color: #383097;
    transition: background-color .3s, color .3s, box-shadow .3s;
    font-weight: 100;
    padding: .5rem
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover, .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
    color: #383097
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    margin-right: .5rem
}

.p-datepicker table {
    font-size: 1rem;
    margin: .5rem 0
}

.p-datepicker table th {
    padding: .5rem
}

.p-datepicker table th > span {
    width: 2.5rem;
    height: 2.5rem
}

.p-datepicker table td {
    padding: .5rem
}

.p-datepicker table td > span {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    transition: box-shadow .3s;
    border: 1px solid transparent
}

.p-datepicker table td > span.p-highlight {
    color: #fff;
    background: #383097
}

.p-datepicker table td > span:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-datepicker table td.p-datepicker-today > span {
    background: #eeedf2;
    color: #383097;
    border-color: transparent
}

.p-datepicker table td.p-datepicker-today > span.p-highlight {
    color: #fff;
    background: #383097
}

.p-datepicker .p-datepicker-buttonbar {
    padding: 1rem 0;
    border-top: 1px solid #f7f6fb
}

.p-datepicker .p-datepicker-buttonbar .p-button {
    width: auto
}

.p-datepicker .p-timepicker {
    border-top: 1px solid #f7f6fb;
    padding: .5rem
}

.p-datepicker .p-timepicker button {
    width: 2rem;
    height: 2rem;
    color: #7267b4;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color .3s, color .3s, box-shadow .3s
}

.p-datepicker .p-timepicker button:enabled:hover {
    color: #383097;
    border-color: transparent;
    background: #f7f6fb
}

.p-datepicker .p-timepicker button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-datepicker .p-timepicker button:last-child {
    margin-top: .2em
}

.p-datepicker .p-timepicker span {
    font-size: 1.25rem
}

.p-datepicker .p-timepicker > div {
    padding: 0 .5rem
}

.p-datepicker.p-datepicker-timeonly .p-timepicker {
    border-top: 0 none
}

.p-datepicker .p-monthpicker {
    margin: .5rem 0
}

.p-datepicker .p-monthpicker .p-monthpicker-month {
    padding: .5rem;
    transition: box-shadow .3s;
    border-radius: 6px
}

.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    color: #fff;
    background: #383097
}

.p-datepicker .p-yearpicker {
    margin: .5rem 0
}

.p-datepicker .p-yearpicker .p-yearpicker-year {
    padding: .5rem;
    transition: box-shadow .3s;
    border-radius: 6px
}

.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
    color: #fff;
    background: #383097
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
    border-left: 1px solid #f7f6fb;
    padding-right: .5rem;
    padding-left: .5rem;
    padding-top: 0;
    padding-bottom: 0
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
    padding-left: 0;
    border-left: 0 none
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
    padding-right: 0
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
    background: #f7f6fb
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
    background: #f7f6fb
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
    background: #f7f6fb
}

.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

p-calendar.p-calendar-clearable .p-inputtext {
    padding-right: 2.5rem
}

p-calendar.p-calendar-clearable .p-calendar-clear-icon {
    color: #7267b4;
    right: .75rem
}

p-calendar.p-calendar-clearable.p-calendar-w-btn .p-calendar-clear-icon {
    color: #7267b4;
    right: 3.607rem
}

@media screen and (max-width: 769px) {
    .p-datepicker table th, .p-datepicker table td {
        padding: 0
    }
}

.p-cascadeselect {
    background: #fff;
    border: 2px solid #eeedf2;
    transition: background-color .3s, color .3s, border-color .3s, box-shadow .3s;
    border-radius: 6px
}

.p-cascadeselect:not(.p-disabled):hover {
    border-color: #f4f3f8
}

.p-cascadeselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1;
    border-color: #f2f1f6
}

.p-cascadeselect .p-cascadeselect-label {
    background: transparent;
    border: 0 none;
    padding: .5rem .75rem
}

.p-cascadeselect .p-cascadeselect-label.p-placeholder {
    color: #7267b4
}

.p-cascadeselect .p-cascadeselect-label:enabled:focus {
    outline: 0 none;
    box-shadow: none
}

.p-cascadeselect .p-cascadeselect-trigger {
    background: transparent;
    color: #7267b4;
    width: 2.857rem;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px
}

.p-cascadeselect-panel {
    background: #fff;
    color: #383097;
    border: 0 none;
    border-radius: 6px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.p-cascadeselect-panel .p-cascadeselect-items {
    padding: .5rem .5rem
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
    margin: 0 0 4px 0;
    border: 0 none;
    color: #383097;
    background: transparent;
    transition: box-shadow .3s;
    border-radius: 6px
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
    padding: .5rem 1rem
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #a6a1e1
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
    color: #fff;
    background: #383097
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: #383097;
    background: #f7f6fb
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
    font-size: .875rem
}

p-cascadeselect.ng-dirty.ng-invalid > .p-cascadeselect {
    border-color: #f88c79
}

.p-input-filled .p-cascadeselect {
    background: #f4f3f8
}

.p-input-filled .p-cascadeselect:not(.p-disabled):hover {
    background-color: #f4f3f8
}

.p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
    background-color: #f4f3f8
}

p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-label {
    padding-right: .75rem
}

p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-clear-icon {
    color: #7267b4;
    right: 2.857rem
}

.p-checkbox {
    width: 20px;
    height: 20px
}

.p-checkbox .p-checkbox-box {
    border: 2px solid #eeedf2;
    background: #fff;
    width: 20px;
    height: 20px;
    color: #383097;
    border-radius: 6px;
    transition: background-color .3s, color .3s, border-color .3s, box-shadow .3s
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
    transition-duration: .3s;
    color: #fff;
    font-size: 14px
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #383097;
    background: #383097
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #f4f3f8
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1;
    border-color: #f2f1f6
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: #322b88;
    background: #322b88;
    color: #fff
}

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
    border-color: #f88c79
}

.p-input-filled .p-checkbox .p-checkbox-box {
    background-color: #f4f3f8
}

.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
    background: #383097
}

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    background-color: #f4f3f8
}

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background: #322b88
}

.p-checkbox-label {
    margin-left: .5rem
}

.p-highlight .p-checkbox .p-checkbox-box {
    border-color: #fff
}

.p-chips .p-chips-multiple-container {
    padding: .25rem .75rem
}

.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
    border-color: #f4f3f8
}

.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1;
    border-color: #f2f1f6
}

.p-chips .p-chips-multiple-container .p-chips-token {
    padding: .25rem .75rem;
    margin-right: .5rem;
    background: #383097;
    color: #fff;
    border-radius: 6px
}

.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
    margin-left: .5rem
}

.p-chips .p-chips-multiple-container .p-chips-input-token {
    padding: .25rem 0
}

.p-chips .p-chips-multiple-container .p-chips-input-token input {
    font-family: CeraPro-Regular;
    font-size: 1rem;
    color: #383097;
    padding: 0;
    margin: 0
}

p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
    border-color: #f88c79
}

p-chips.p-chips-clearable .p-inputtext {
    padding-right: 1.75rem
}

p-chips.p-chips-clearable .p-chips-clear-icon {
    color: #7267b4;
    right: .75rem
}

.p-colorpicker-preview, .p-fluid .p-colorpicker-preview.p-inputtext {
    width: 2rem;
    height: 2rem
}

.p-colorpicker-panel {
    background: #585858;
    border: 1px solid #585858
}

.p-colorpicker-panel .p-colorpicker-color-handle, .p-colorpicker-panel .p-colorpicker-hue-handle {
    border-color: #fff
}

.p-colorpicker-overlay-panel {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.p-dropdown {
    background: #fff;
    border: 2px solid #eeedf2;
    transition: background-color .3s, color .3s, border-color .3s, box-shadow .3s;
    border-radius: 6px
}

.p-dropdown:not(.p-disabled):hover {
    border-color: #f4f3f8
}

.p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1;
    border-color: #f2f1f6
}

.p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 1.75rem
}

.p-dropdown .p-dropdown-label {
    background: transparent;
    border: 0 none
}

.p-dropdown .p-dropdown-label.p-placeholder {
    color: #7267b4
}

.p-dropdown .p-dropdown-label:enabled:focus {
    outline: 0 none;
    box-shadow: none
}

.p-dropdown .p-dropdown-trigger {
    background: transparent;
    color: #7267b4;
    width: 2.857rem;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px
}

.p-dropdown .p-dropdown-clear-icon {
    color: #7267b4;
    right: 2.857rem
}

.p-dropdown-panel {
    background: #fff;
    color: #383097;
    border: 0 none;
    border-radius: 6px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.p-dropdown-panel .p-dropdown-header {
    padding: .5rem 1.5rem;
    border-bottom: 0 none;
    color: #383097;
    background: #fff;
    margin: 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    padding-right: 1.75rem;
    margin-right: -1.75rem
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
    right: .75rem;
    color: #7267b4
}

.p-dropdown-panel .p-dropdown-items:not(.p-dropdown-virtualscroll) {
    padding: .5rem .5rem
}

.p-dropdown-panel .p-dropdown-items.p-dropdown-virtualscroll .cdk-virtual-scroll-content-wrapper {
    padding: .5rem .5rem
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    margin: 0 0 4px 0;
    padding: .5rem 1rem;
    border: 0 none;
    color: #383097;
    background: transparent;
    transition: box-shadow .3s;
    border-radius: 6px
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #fff;
    background: #383097
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    color: #383097;
    background: #f7f6fb
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
    padding: .5rem 1rem;
    color: #383097;
    background: transparent
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
    margin: 0;
    padding: .75rem 1rem;
    color: #383097;
    background: #fff;
    font-weight: 100
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
    border-color: #f88c79
}

.p-input-filled .p-dropdown {
    background: #f4f3f8
}

.p-input-filled .p-dropdown:not(.p-disabled):hover {
    background-color: #f4f3f8
}

.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
    background-color: #f4f3f8
}

.p-input-filled .p-dropdown:not(.p-disabled).p-focus .p-inputtext {
    background-color: transparent
}

.p-editor-container .p-editor-toolbar {
    background: #fff;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px
}

.p-editor-container .p-editor-toolbar.ql-snow {
    border: 2px solid #f7f6fb
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
    stroke: #7267b4
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
    fill: #7267b4
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
    border: 0 none;
    color: #7267b4
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
    color: #383097
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
    stroke: #383097
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
    fill: #383097
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    color: #383097
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
    stroke: #383097
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
    fill: #383097
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    background: #fff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    border-radius: 6px;
    padding: .5rem .5rem
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
    color: #383097
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
    color: #383097;
    background: #f7f6fb
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
    padding: .5rem 1rem
}

.p-editor-container .p-editor-content {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px
}

.p-editor-container .p-editor-content.ql-snow {
    border: 2px solid #f7f6fb
}

.p-editor-container .p-editor-content .ql-editor {
    background: #fff;
    color: #383097;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px
}

.p-editor-container .ql-snow.ql-toolbar button:hover, .p-editor-container .ql-snow.ql-toolbar button:focus {
    color: #383097
}

.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke, .p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
    stroke: #383097
}

.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill, .p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
    fill: #383097
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active, .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active, .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
    color: #383097
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke, .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
    stroke: #383097
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill, .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
    fill: #383097
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label, .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label, .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
    color: #383097
}

.p-inputgroup-addon {
    background: #f3f2f7;
    color: #7267b4;
    border-top: 2px solid #eeedf2;
    border-left: 2px solid #eeedf2;
    border-bottom: 2px solid #eeedf2;
    padding: .5rem .75rem;
    min-width: 2.857rem
}

.p-inputgroup-addon:last-child {
    border-right: 2px solid #eeedf2
}

.p-inputgroup > .p-component, .p-inputgroup > .p-element, .p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext, .p-inputgroup > .p-float-label > .p-component {
    border-radius: 0;
    margin: 0
}

.p-inputgroup > .p-component + .p-inputgroup-addon, .p-inputgroup > .p-element + .p-inputgroup-addon, .p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext + .p-inputgroup-addon, .p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
    border-left: 0 none
}

.p-inputgroup > .p-component:focus, .p-inputgroup > .p-element:focus, .p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext:focus, .p-inputgroup > .p-float-label > .p-component:focus {
    z-index: 1
}

.p-inputgroup > .p-component:focus ~ label, .p-inputgroup > .p-element:focus ~ label, .p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext:focus ~ label, .p-inputgroup > .p-float-label > .p-component:focus ~ label {
    z-index: 1
}

.p-inputgroup-addon:first-child, .p-inputgroup button:first-child, .p-inputgroup input:first-child, .p-inputgroup > .p-inputwrapper:first-child > .p-component, .p-inputgroup > .p-inputwrapper:first-child > .p-component > .p-inputtext {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px
}

.p-inputgroup .p-float-label:first-child input {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px
}

.p-inputgroup-addon:last-child, .p-inputgroup button:last-child, .p-inputgroup input:last-child, .p-inputgroup > .p-inputwrapper:last-child > .p-component, .p-inputgroup > .p-inputwrapper:last-child > .p-component > .p-inputtext {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px
}

.p-inputgroup .p-float-label:last-child input {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px
}

.p-fluid .p-inputgroup .p-button {
    width: auto
}

.p-fluid .p-inputgroup .p-button.p-button-icon-only {
    width: 2.857rem
}

p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
    border-color: #f88c79
}

p-inputmask.p-inputmask-clearable .p-inputtext {
    padding-right: 2.5rem
}

p-inputmask.p-inputmask-clearable .p-inputmask-clear-icon {
    color: #7267b4;
    right: .75rem
}

p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
    border-color: #f88c79
}

p-inputnumber.p-inputnumber-clearable .p-inputnumber-input {
    padding-right: 2.5rem
}

p-inputnumber.p-inputnumber-clearable .p-inputnumber-clear-icon {
    color: #7267b4;
    right: .75rem
}

p-inputnumber.p-inputnumber-clearable .p-inputnumber-buttons-stacked .p-inputnumber-clear-icon {
    right: 3.607rem
}

p-inputnumber.p-inputnumber-clearable .p-inputnumber-buttons-horizontal .p-inputnumber-clear-icon {
    right: 3.607rem
}

.p-inputswitch {
    width: 3rem;
    height: 1.75rem
}

.p-inputswitch .p-inputswitch-slider {
    background: #cecece;
    transition: background-color .3s, color .3s, border-color .3s, box-shadow .3s;
    border-radius: 6px
}

.p-inputswitch .p-inputswitch-slider:before {
    background: #fff;
    width: 1.25rem;
    height: 1.25rem;
    left: .25rem;
    margin-top: -0.625rem;
    border-radius: 6px;
    transition-duration: .3s
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(1.25rem)
}

.p-inputswitch.p-focus .p-inputswitch-slider {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
    background: #b9b9b9
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #383097
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background: #fff
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: #352e8f
}

p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
    border-color: #f88c79
}

.p-inputtext {
    font-family: CeraPro-Regular;
    font-size: 1rem;
    color: #383097;
    background: #fff;
    padding: .5rem .75rem;
    border: 2px solid #eeedf2;
    transition: background-color .3s, color .3s, border-color .3s, box-shadow .3s;
    appearance: none;
    border-radius: 6px
}

.p-inputtext:enabled:hover {
    border-color: #f4f3f8
}

.p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1;
    border-color: #f2f1f6
}

.p-inputtext.ng-dirty.ng-invalid {
    border-color: #f88c79
}

.p-inputtext.p-inputtext-sm {
    font-size: .875rem;
    padding: .4375rem .65625rem
}

.p-inputtext.p-inputtext-lg {
    font-size: 1.25rem;
    padding: .625rem .9375rem
}

.p-float-label > label {
    left: .75rem;
    color: #7267b4;
    transition-duration: .3s
}

.p-float-label > .ng-invalid.ng-dirty + label {
    color: #f88c79
}

.p-input-icon-left > i:first-of-type {
    left: .75rem;
    color: #7267b4
}

.p-input-icon-left > .p-inputtext {
    padding-left: 2.5rem
}

.p-input-icon-left.p-float-label > label {
    left: 2.5rem
}

.p-input-icon-right > i:last-of-type {
    right: .75rem;
    color: #7267b4
}

.p-input-icon-right > .p-inputtext {
    padding-right: 2.5rem
}

::-webkit-input-placeholder {
    color: #7267b4
}

:-moz-placeholder {
    color: #7267b4
}

::-moz-placeholder {
    color: #7267b4
}

:-ms-input-placeholder {
    color: #7267b4
}

.p-input-filled .p-inputtext {
    background-color: #f4f3f8
}

.p-input-filled .p-inputtext:enabled:hover {
    background-color: #f4f3f8
}

.p-input-filled .p-inputtext:enabled:focus {
    background-color: #f4f3f8
}

.p-inputtext-sm .p-inputtext {
    font-size: .875rem;
    padding: .4375rem .65625rem
}

.p-inputtext-lg .p-inputtext {
    font-size: 1.25rem;
    padding: .625rem .9375rem
}

.p-listbox {
    background: #fff;
    color: #383097;
    border: 2px solid #eeedf2;
    border-radius: 6px
}

.p-listbox .p-listbox-header {
    padding: .5rem 1.5rem;
    border-bottom: 0 none;
    color: #383097;
    background: #fff;
    margin: 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px
}

.p-listbox .p-listbox-header .p-listbox-filter {
    padding-right: 1.75rem
}

.p-listbox .p-listbox-header .p-listbox-filter-icon {
    right: .75rem;
    color: #7267b4
}

.p-listbox .p-listbox-header .p-checkbox {
    margin-right: .5rem
}

.p-listbox .p-listbox-list {
    padding: .5rem .5rem
}

.p-listbox .p-listbox-list .p-listbox-item {
    margin: 0 0 4px 0;
    padding: .5rem 1rem;
    border: 0 none;
    color: #383097;
    transition: box-shadow .3s;
    border-radius: 6px
}

.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
    color: #fff;
    background: #383097
}

.p-listbox .p-listbox-list .p-listbox-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #a6a1e1
}

.p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
    margin-right: .5rem
}

.p-listbox .p-listbox-list .p-listbox-item-group {
    margin: 0;
    padding: .75rem 1rem;
    color: #383097;
    background: #fff;
    font-weight: 100
}

.p-listbox .p-listbox-list .p-listbox-empty-message {
    padding: .5rem 1rem;
    color: #383097;
    background: transparent
}

.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
    color: #383097;
    background: #f7f6fb
}

p-listbox.ng-dirty.ng-invalid > .p-listbox {
    border-color: #f88c79
}

.p-multiselect {
    background: #fff;
    border: 2px solid #eeedf2;
    transition: background-color .3s, color .3s, border-color .3s, box-shadow .3s;
    border-radius: 6px
}

.p-multiselect:not(.p-disabled):hover {
    border-color: #f4f3f8
}

.p-multiselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1;
    border-color: #f2f1f6
}

.p-multiselect .p-multiselect-label {
    padding: .5rem .75rem;
    transition: background-color .3s, color .3s, border-color .3s, box-shadow .3s
}

.p-multiselect .p-multiselect-label.p-placeholder {
    color: #7267b4
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding: .25rem .75rem;
    margin-right: .5rem;
    background: #383097;
    color: #fff;
    border-radius: 6px
}

.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
    margin-left: .5rem
}

.p-multiselect .p-multiselect-trigger {
    background: transparent;
    color: #7267b4;
    width: 2.857rem;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px
}

.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
    padding: .25rem .75rem
}

.p-multiselect-panel {
    background: #fff;
    color: #383097;
    border: 0 none;
    border-radius: 6px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.p-multiselect-panel .p-multiselect-header {
    padding: .5rem 1.5rem;
    border-bottom: 0 none;
    color: #383097;
    background: #fff;
    margin: 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
    padding-right: 1.75rem
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
    right: .75rem;
    color: #7267b4
}

.p-multiselect-panel .p-multiselect-header .p-checkbox {
    margin-right: .5rem
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    margin-left: .5rem;
    width: 2rem;
    height: 2rem;
    color: #7267b4;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color .3s, color .3s, box-shadow .3s
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
    color: #383097;
    border-color: transparent;
    background: #f7f6fb
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-multiselect-panel .p-multiselect-items:not(.p-multiselect-virtualscroll) {
    padding: .5rem .5rem
}

.p-multiselect-panel .p-multiselect-items.p-multiselect-virtualscroll .cdk-virtual-scroll-content-wrapper {
    padding: .5rem .5rem
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    margin: 0 0 4px 0;
    padding: .5rem 1rem;
    border: 0 none;
    color: #383097;
    background: transparent;
    transition: box-shadow .3s;
    border-radius: 6px
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: #fff;
    background: #383097
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: #383097;
    background: #f7f6fb
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #a6a1e1
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    margin-right: .5rem
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
    margin: 0;
    padding: .75rem 1rem;
    color: #383097;
    background: #fff;
    font-weight: 100
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
    padding: .5rem 1rem;
    color: #383097;
    background: transparent
}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
    border-color: #f88c79
}

.p-input-filled .p-multiselect {
    background: #f4f3f8
}

.p-input-filled .p-multiselect:not(.p-disabled):hover {
    background-color: #f4f3f8
}

.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
    background-color: #f4f3f8
}

p-multiselect.p-multiselect-clearable .p-multiselect-label-container {
    padding-right: 1.75rem
}

p-multiselect.p-multiselect-clearable .p-multiselect-clear-icon {
    color: #7267b4;
    right: 2.857rem
}

p-password.ng-invalid.ng-dirty > .p-password > .p-inputtext {
    border-color: #f88c79
}

.p-password-panel {
    padding: 1rem;
    background: #fff;
    color: #383097;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    border-radius: 6px
}

.p-password-panel .p-password-meter {
    margin-bottom: .5rem;
    background: #f7f6fb
}

.p-password-panel .p-password-meter .p-password-strength.weak {
    background: #d45472
}

.p-password-panel .p-password-meter .p-password-strength.medium {
    background: #ff922a
}

.p-password-panel .p-password-meter .p-password-strength.strong {
    background: #8bae2c
}

p-password.p-password-clearable .p-password-input {
    padding-right: 2.5rem
}

p-password.p-password-clearable .p-password-clear-icon {
    color: #7267b4;
    right: .75rem
}

p-password.p-password-clearable.p-password-mask .p-password-input {
    padding-right: 4.25rem
}

p-password.p-password-clearable.p-password-mask .p-password-clear-icon {
    color: #7267b4;
    right: 2.5rem
}

.p-radiobutton {
    width: 20px;
    height: 20px
}

.p-radiobutton .p-radiobutton-box {
    border: 2px solid #eeedf2;
    background: #fff;
    width: 20px;
    height: 20px;
    color: #383097;
    border-radius: 50%;
    transition: background-color .3s, color .3s, border-color .3s, box-shadow .3s
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: #f4f3f8
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1;
    border-color: #f2f1f6
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    width: 12px;
    height: 12px;
    transition-duration: .3s;
    background-color: #fff
}

.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: #383097;
    background: #383097
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: #322b88;
    background: #322b88;
    color: #fff
}

p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
    border-color: #f88c79
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
    background-color: #f4f3f8
}

.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
    background-color: #f4f3f8
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
    background: #383097
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    background: #322b88
}

.p-radiobutton-label {
    margin-left: .5rem
}

.p-highlight .p-radiobutton .p-radiobutton-box {
    border-color: #fff
}

.p-rating .p-rating-icon {
    color: #383097;
    margin-left: .5rem;
    transition: background-color .3s, color .3s, border-color .3s, box-shadow .3s;
    font-size: 1.143rem
}

.p-rating .p-rating-icon.p-rating-cancel {
    color: #df7e6c
}

.p-rating .p-rating-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-rating .p-rating-icon:first-child {
    margin-left: 0
}

.p-rating .p-rating-icon.pi-star-fill {
    color: #383097
}

.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
    color: #383097
}

.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon.p-rating-cancel:hover {
    color: #f88c79
}

.p-highlight .p-rating .p-rating-icon {
    color: #fff
}

.p-selectbutton .p-button {
    background: #fff;
    border: 2px solid #eeedf2;
    color: #383097;
    transition: background-color .3s, color .3s, border-color .3s, box-shadow .3s
}

.p-selectbutton .p-button .p-button-icon-left, .p-selectbutton .p-button .p-button-icon-right {
    color: #7267b4
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background: #f7f6fb;
    border-color: #eeedf2;
    color: #383097
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left, .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
    color: #7267b4
}

.p-selectbutton .p-button.p-highlight {
    background: #383097;
    border-color: #383097;
    color: #fff
}

.p-selectbutton .p-button.p-highlight .p-button-icon-left, .p-selectbutton .p-button.p-highlight .p-button-icon-right {
    color: #fff
}

.p-selectbutton .p-button.p-highlight:hover {
    background: #352e8f;
    border-color: #352e8f;
    color: #fff
}

.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left, .p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
    color: #fff
}

p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
    border-color: #f88c79
}

.p-slider {
    background: #f7f6fb;
    border: 0 none;
    border-radius: 6px
}

.p-slider.p-slider-horizontal {
    height: .286rem
}

.p-slider.p-slider-horizontal .p-slider-handle {
    margin-top: -0.5715rem;
    margin-left: -0.5715rem
}

.p-slider.p-slider-vertical {
    width: .286rem
}

.p-slider.p-slider-vertical .p-slider-handle {
    margin-left: -0.5715rem;
    margin-bottom: -0.5715rem
}

.p-slider .p-slider-handle {
    height: 1.143rem;
    width: 1.143rem;
    background: #fff;
    border: 2px solid #383097;
    border-radius: 50%;
    transition: background-color .3s, color .3s, border-color .3s, box-shadow .3s
}

.p-slider .p-slider-handle:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-slider .p-slider-range {
    background: #383097
}

.p-slider:not(.p-disabled) .p-slider-handle:hover {
    background: #383097;
    border-color: #383097
}

.p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {
    transition: background-color .3s, color .3s, border-color .3s, box-shadow .3s, left .3s
}

.p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {
    transition: width .3s
}

.p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {
    transition: background-color .3s, color .3s, border-color .3s, box-shadow .3s, bottom .3s
}

.p-slider.p-slider-animate.p-slider-vertical .p-slider-range {
    transition: height .3s
}

.p-togglebutton.p-button {
    background: #fff;
    border: 2px solid #eeedf2;
    color: #383097;
    transition: background-color .3s, color .3s, border-color .3s, box-shadow .3s
}

.p-togglebutton.p-button .p-button-icon-left, .p-togglebutton.p-button .p-button-icon-right {
    color: #7267b4
}

.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
    background: #f7f6fb;
    border-color: #eeedf2;
    color: #383097
}

.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left, .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
    color: #7267b4
}

.p-togglebutton.p-button.p-highlight {
    background: #383097;
    border-color: #383097;
    color: #fff
}

.p-togglebutton.p-button.p-highlight .p-button-icon-left, .p-togglebutton.p-button.p-highlight .p-button-icon-right {
    color: #fff
}

.p-togglebutton.p-button.p-highlight:hover {
    background: #352e8f;
    border-color: #352e8f;
    color: #fff
}

.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left, .p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
    color: #fff
}

p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
    border-color: #f88c79
}

.p-treeselect {
    background: #fff;
    border: 2px solid #eeedf2;
    transition: background-color .3s, color .3s, border-color .3s, box-shadow .3s;
    border-radius: 6px
}

.p-treeselect:not(.p-disabled):hover {
    border-color: #f4f3f8
}

.p-treeselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1;
    border-color: #f2f1f6
}

.p-treeselect .p-treeselect-label {
    padding: .5rem .75rem;
    transition: background-color .3s, color .3s, border-color .3s, box-shadow .3s
}

.p-treeselect .p-treeselect-label.p-placeholder {
    color: #7267b4
}

.p-treeselect.p-treeselect-chip .p-treeselect-token {
    padding: .25rem .75rem;
    margin-right: .5rem;
    background: #f7f6fb;
    color: #383097;
    border-radius: 16px
}

.p-treeselect .p-treeselect-trigger {
    background: transparent;
    color: #7267b4;
    width: 2.857rem;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px
}

p-treeselect.ng-invalid.ng-dirty > .p-treeselect {
    border-color: #f88c79
}

.p-inputwrapper-filled .p-treeselect.p-treeselect-chip .p-treeselect-label {
    padding: .25rem .75rem
}

.p-treeselect-panel {
    background: #fff;
    color: #383097;
    border: 0 none;
    border-radius: 6px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
    border: 0 none
}

.p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
    padding: .5rem 1rem;
    color: #383097;
    background: transparent
}

.p-input-filled .p-treeselect {
    background: #f4f3f8
}

.p-input-filled .p-treeselect:not(.p-disabled):hover {
    background-color: #f4f3f8
}

.p-input-filled .p-treeselect:not(.p-disabled).p-focus {
    background-color: #f4f3f8
}

p-treeselect.p-treeselect-clearable .p-treeselect-label-container {
    padding-right: 1.75rem
}

p-treeselect.p-treeselect-clearable .p-treeselect-clear-icon {
    color: #7267b4;
    right: 2.857rem
}

.p-button {
    color: #fff;
    background: #383097;
    border: 2px solid #383097;
    padding: .5rem 1rem;
    font-size: 1rem;
    transition: background-color .3s, color .3s, border-color .3s, box-shadow .3s;
    border-radius: 6px
}

.p-button:enabled:hover {
    background: #352e8f;
    color: #fff;
    border-color: #352e8f
}

.p-button:enabled:active {
    background: #322b88;
    color: #fff;
    border-color: #322b88
}

.p-button.p-button-outlined {
    background-color: transparent;
    color: #383097;
    border: 2px solid
}

.p-button.p-button-outlined:enabled:hover {
    background: rgba(56, 48, 151, .04);
    color: #383097;
    border: 2px solid
}

.p-button.p-button-outlined:enabled:active {
    background: rgba(56, 48, 151, .16);
    color: #383097;
    border: 2px solid
}

.p-button.p-button-outlined.p-button-plain {
    color: #7267b4;
    border-color: #7267b4
}

.p-button.p-button-outlined.p-button-plain:enabled:hover {
    background: #f7f6fb;
    color: #7267b4
}

.p-button.p-button-outlined.p-button-plain:enabled:active {
    background: #f7f6fb;
    color: #7267b4
}

.p-button.p-button-text {
    background-color: transparent;
    color: #383097;
    border-color: transparent
}

.p-button.p-button-text:enabled:hover {
    background: rgba(56, 48, 151, .04);
    color: #383097;
    border-color: transparent
}

.p-button.p-button-text:enabled:active {
    background: rgba(56, 48, 151, .16);
    color: #383097;
    border-color: transparent
}

.p-button.p-button-text.p-button-plain {
    color: #7267b4
}

.p-button.p-button-text.p-button-plain:enabled:hover {
    background: #f7f6fb;
    color: #7267b4
}

.p-button.p-button-text.p-button-plain:enabled:active {
    background: #f7f6fb;
    color: #7267b4
}

.p-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-button .p-button-icon-left {
    margin-right: .5rem
}

.p-button .p-button-icon-right {
    margin-left: .5rem
}

.p-button .p-button-icon-bottom {
    margin-top: .5rem
}

.p-button .p-button-icon-top {
    margin-bottom: .5rem
}

.p-button .p-badge {
    margin-left: .5rem;
    min-width: 1rem;
    height: 1rem;
    line-height: 1rem;
    color: #383097;
    background-color: #fff
}

.p-button.p-button-raised {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.p-button.p-button-rounded {
    border-radius: 2rem
}

.p-button.p-button-icon-only {
    width: 2.857rem;
    padding: .5rem 0
}

.p-button.p-button-icon-only .p-button-icon-left, .p-button.p-button-icon-only .p-button-icon-right {
    margin: 0
}

.p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
    height: 2.857rem
}

.p-button.p-button-sm {
    font-size: .875rem;
    padding: .4375rem .875rem
}

.p-button.p-button-sm .p-button-icon {
    font-size: .875rem
}

.p-button.p-button-lg {
    font-size: 1.25rem;
    padding: .625rem 1.25rem
}

.p-button.p-button-lg .p-button-icon {
    font-size: 1.25rem
}

.p-button.p-button-loading-label-only .p-button-label {
    margin-left: .5rem
}

.p-button.p-button-loading-label-only .p-button-loading-icon {
    margin-right: 0
}

.p-fluid .p-button {
    width: 100%
}

.p-fluid .p-button-icon-only {
    width: 2.857rem
}

.p-fluid .p-buttonset {
    display: flex
}

.p-fluid .p-buttonset .p-button {
    flex: 1
}

.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
    color: #fff;
    background: #dd3636;
    border: 1px solid #dd3636
}

.p-button.p-button-secondary:enabled:hover, .p-buttonset.p-button-secondary > .p-button:enabled:hover, .p-splitbutton.p-button-secondary > .p-button:enabled:hover {
    background: #d42424;
    color: #fff;
    border-color: #d42424
}

.p-button.p-button-secondary:enabled:focus, .p-buttonset.p-button-secondary > .p-button:enabled:focus, .p-splitbutton.p-button-secondary > .p-button:enabled:focus {
    box-shadow: 0 0 0 .1rem #f1afaf
}

.p-button.p-button-secondary:enabled:active, .p-buttonset.p-button-secondary > .p-button:enabled:active, .p-splitbutton.p-button-secondary > .p-button:enabled:active {
    background: #bc2020;
    color: #fff;
    border-color: #bc2020
}

.p-button.p-button-secondary.p-button-outlined, .p-buttonset.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
    background-color: transparent;
    color: #dd3636;
    border: 2px solid
}

.p-button.p-button-secondary.p-button-outlined:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover {
    background: rgba(221, 54, 54, .04);
    color: #dd3636;
    border: 2px solid
}

.p-button.p-button-secondary.p-button-outlined:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
    background: rgba(221, 54, 54, .16);
    color: #dd3636;
    border: 2px solid
}

.p-button.p-button-secondary.p-button-text, .p-buttonset.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text {
    background-color: transparent;
    color: #dd3636;
    border-color: transparent
}

.p-button.p-button-secondary.p-button-text:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
    background: rgba(221, 54, 54, .04);
    border-color: transparent;
    color: #dd3636
}

.p-button.p-button-secondary.p-button-text:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
    background: rgba(221, 54, 54, .16);
    border-color: transparent;
    color: #dd3636
}

.p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button {
    color: #fff;
    background: #276ef1;
    border: 1px solid #276ef1
}

.p-button.p-button-info:enabled:hover, .p-buttonset.p-button-info > .p-button:enabled:hover, .p-splitbutton.p-button-info > .p-button:enabled:hover {
    background: #0f5ded;
    color: #fff;
    border-color: #0f5ded
}

.p-button.p-button-info:enabled:focus, .p-buttonset.p-button-info > .p-button:enabled:focus, .p-splitbutton.p-button-info > .p-button:enabled:focus {
    box-shadow: 0 0 0 .1rem #a9c5f9
}

.p-button.p-button-info:enabled:active, .p-buttonset.p-button-info > .p-button:enabled:active, .p-splitbutton.p-button-info > .p-button:enabled:active {
    background: #0e53d2;
    color: #fff;
    border-color: #0e53d2
}

.p-button.p-button-info.p-button-outlined, .p-buttonset.p-button-info > .p-button.p-button-outlined, .p-splitbutton.p-button-info > .p-button.p-button-outlined {
    background-color: transparent;
    color: #276ef1;
    border: 2px solid
}

.p-button.p-button-info.p-button-outlined:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover {
    background: rgba(39, 110, 241, .04);
    color: #276ef1;
    border: 2px solid
}

.p-button.p-button-info.p-button-outlined:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
    background: rgba(39, 110, 241, .16);
    color: #276ef1;
    border: 2px solid
}

.p-button.p-button-info.p-button-text, .p-buttonset.p-button-info > .p-button.p-button-text, .p-splitbutton.p-button-info > .p-button.p-button-text {
    background-color: transparent;
    color: #276ef1;
    border-color: transparent
}

.p-button.p-button-info.p-button-text:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover {
    background: rgba(39, 110, 241, .04);
    border-color: transparent;
    color: #276ef1
}

.p-button.p-button-info.p-button-text:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active {
    background: rgba(39, 110, 241, .16);
    border-color: transparent;
    color: #276ef1
}

.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
    color: #fff;
    background: #3aa76d;
    border: 1px solid #3aa76d
}

.p-button.p-button-success:enabled:hover, .p-buttonset.p-button-success > .p-button:enabled:hover, .p-splitbutton.p-button-success > .p-button:enabled:hover {
    background: #349662;
    color: #fff;
    border-color: #349662
}

.p-button.p-button-success:enabled:focus, .p-buttonset.p-button-success > .p-button:enabled:focus, .p-splitbutton.p-button-success > .p-button:enabled:focus {
    box-shadow: 0 0 0 .1rem #aae2c4
}

.p-button.p-button-success:enabled:active, .p-buttonset.p-button-success > .p-button:enabled:active, .p-splitbutton.p-button-success > .p-button:enabled:active {
    background: #2e8657;
    color: #fff;
    border-color: #2e8657
}

.p-button.p-button-success.p-button-outlined, .p-buttonset.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined {
    background-color: transparent;
    color: #3aa76d;
    border: 2px solid
}

.p-button.p-button-success.p-button-outlined:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover {
    background: rgba(58, 167, 109, .04);
    color: #3aa76d;
    border: 2px solid
}

.p-button.p-button-success.p-button-outlined:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
    background: rgba(58, 167, 109, .16);
    color: #3aa76d;
    border: 2px solid
}

.p-button.p-button-success.p-button-text, .p-buttonset.p-button-success > .p-button.p-button-text, .p-splitbutton.p-button-success > .p-button.p-button-text {
    background-color: transparent;
    color: #3aa76d;
    border-color: transparent
}

.p-button.p-button-success.p-button-text:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover {
    background: rgba(58, 167, 109, .04);
    border-color: transparent;
    color: #3aa76d
}

.p-button.p-button-success.p-button-text:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active {
    background: rgba(58, 167, 109, .16);
    border-color: transparent;
    color: #3aa76d
}

.p-button.p-button-warning, .p-buttonset.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button {
    color: #fff;
    background: #ff922a;
    border: 1px solid #ff922a
}

.p-button.p-button-warning:enabled:hover, .p-buttonset.p-button-warning > .p-button:enabled:hover, .p-splitbutton.p-button-warning > .p-button:enabled:hover {
    background: #ff830c;
    color: #fff;
    border-color: #ff830c
}

.p-button.p-button-warning:enabled:focus, .p-buttonset.p-button-warning > .p-button:enabled:focus, .p-splitbutton.p-button-warning > .p-button:enabled:focus {
    box-shadow: 0 0 0 .1rem #ffd3aa
}

.p-button.p-button-warning:enabled:active, .p-buttonset.p-button-warning > .p-button:enabled:active, .p-splitbutton.p-button-warning > .p-button:enabled:active {
    background: #ee7400;
    color: #fff;
    border-color: #ee7400
}

.p-button.p-button-warning.p-button-outlined, .p-buttonset.p-button-warning > .p-button.p-button-outlined, .p-splitbutton.p-button-warning > .p-button.p-button-outlined {
    background-color: transparent;
    color: #ff922a;
    border: 2px solid
}

.p-button.p-button-warning.p-button-outlined:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover {
    background: rgba(255, 146, 42, .04);
    color: #ff922a;
    border: 2px solid
}

.p-button.p-button-warning.p-button-outlined:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
    background: rgba(255, 146, 42, .16);
    color: #ff922a;
    border: 2px solid
}

.p-button.p-button-warning.p-button-text, .p-buttonset.p-button-warning > .p-button.p-button-text, .p-splitbutton.p-button-warning > .p-button.p-button-text {
    background-color: transparent;
    color: #ff922a;
    border-color: transparent
}

.p-button.p-button-warning.p-button-text:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover {
    background: rgba(255, 146, 42, .04);
    border-color: transparent;
    color: #ff922a
}

.p-button.p-button-warning.p-button-text:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active {
    background: rgba(255, 146, 42, .16);
    border-color: transparent;
    color: #ff922a
}

.p-button.p-button-help, .p-buttonset.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button {
    color: #fff;
    background: #276ef1;
    border: 1px solid #276ef1
}

.p-button.p-button-help:enabled:hover, .p-buttonset.p-button-help > .p-button:enabled:hover, .p-splitbutton.p-button-help > .p-button:enabled:hover {
    background: #0f5ded;
    color: #fff;
    border-color: #0f5ded
}

.p-button.p-button-help:enabled:focus, .p-buttonset.p-button-help > .p-button:enabled:focus, .p-splitbutton.p-button-help > .p-button:enabled:focus {
    box-shadow: 0 0 0 .1rem #a9c5f9
}

.p-button.p-button-help:enabled:active, .p-buttonset.p-button-help > .p-button:enabled:active, .p-splitbutton.p-button-help > .p-button:enabled:active {
    background: #0e53d2;
    color: #fff;
    border-color: #0e53d2
}

.p-button.p-button-help.p-button-outlined, .p-buttonset.p-button-help > .p-button.p-button-outlined, .p-splitbutton.p-button-help > .p-button.p-button-outlined {
    background-color: transparent;
    color: #276ef1;
    border: 2px solid
}

.p-button.p-button-help.p-button-outlined:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover {
    background: rgba(39, 110, 241, .04);
    color: #276ef1;
    border: 2px solid
}

.p-button.p-button-help.p-button-outlined:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
    background: rgba(39, 110, 241, .16);
    color: #276ef1;
    border: 2px solid
}

.p-button.p-button-help.p-button-text, .p-buttonset.p-button-help > .p-button.p-button-text, .p-splitbutton.p-button-help > .p-button.p-button-text {
    background-color: transparent;
    color: #276ef1;
    border-color: transparent
}

.p-button.p-button-help.p-button-text:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover {
    background: rgba(39, 110, 241, .04);
    border-color: transparent;
    color: #276ef1
}

.p-button.p-button-help.p-button-text:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active {
    background: rgba(39, 110, 241, .16);
    border-color: transparent;
    color: #276ef1
}

.p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button {
    color: #fff;
    background: #dd3636;
    border: 1px solid #dd3636
}

.p-button.p-button-danger:enabled:hover, .p-buttonset.p-button-danger > .p-button:enabled:hover, .p-splitbutton.p-button-danger > .p-button:enabled:hover {
    background: #d42424;
    color: #fff;
    border-color: #d42424
}

.p-button.p-button-danger:enabled:focus, .p-buttonset.p-button-danger > .p-button:enabled:focus, .p-splitbutton.p-button-danger > .p-button:enabled:focus {
    box-shadow: 0 0 0 .1rem #f1afaf
}

.p-button.p-button-danger:enabled:active, .p-buttonset.p-button-danger > .p-button:enabled:active, .p-splitbutton.p-button-danger > .p-button:enabled:active {
    background: #bc2020;
    color: #fff;
    border-color: #bc2020
}

.p-button.p-button-danger.p-button-outlined, .p-buttonset.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined {
    background-color: transparent;
    color: #dd3636;
    border: 2px solid
}

.p-button.p-button-danger.p-button-outlined:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
    background: rgba(221, 54, 54, .04);
    color: #dd3636;
    border: 2px solid
}

.p-button.p-button-danger.p-button-outlined:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
    background: rgba(221, 54, 54, .16);
    color: #dd3636;
    border: 2px solid
}

.p-button.p-button-danger.p-button-text, .p-buttonset.p-button-danger > .p-button.p-button-text, .p-splitbutton.p-button-danger > .p-button.p-button-text {
    background-color: transparent;
    color: #dd3636;
    border-color: transparent
}

.p-button.p-button-danger.p-button-text:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
    background: rgba(221, 54, 54, .04);
    border-color: transparent;
    color: #dd3636
}

.p-button.p-button-danger.p-button-text:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active {
    background: rgba(221, 54, 54, .16);
    border-color: transparent;
    color: #dd3636
}

.p-button.p-button-link {
    color: #322b88;
    background: transparent;
    border: transparent
}

.p-button.p-button-link:enabled:hover {
    background: transparent;
    color: #322b88;
    border-color: transparent
}

.p-button.p-button-link:enabled:hover .p-button-label {
    text-decoration: underline
}

.p-button.p-button-link:enabled:focus {
    background: transparent;
    box-shadow: 0 0 0 .1rem #a6a1e1;
    border-color: transparent
}

.p-button.p-button-link:enabled:active {
    background: transparent;
    color: #322b88;
    border-color: transparent
}

.p-speeddial-button.p-button.p-button-icon-only {
    width: 4rem;
    height: 4rem
}

.p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
    font-size: 1.3rem
}

.p-speeddial-action {
    width: 3rem;
    height: 3rem;
    background: #383097;
    color: #fff
}

.p-speeddial-action:hover {
    background: #585858;
    color: #fff
}

.p-speeddial-direction-up .p-speeddial-item {
    margin: .25rem 0
}

.p-speeddial-direction-up .p-speeddial-item:first-child {
    margin-bottom: .5rem
}

.p-speeddial-direction-down .p-speeddial-item {
    margin: .25rem 0
}

.p-speeddial-direction-down .p-speeddial-item:first-child {
    margin-top: .5rem
}

.p-speeddial-direction-left .p-speeddial-item {
    margin: 0 .25rem
}

.p-speeddial-direction-left .p-speeddial-item:first-child {
    margin-right: .5rem
}

.p-speeddial-direction-right .p-speeddial-item {
    margin: 0 .25rem
}

.p-speeddial-direction-right .p-speeddial-item:first-child {
    margin-left: .5rem
}

.p-speeddial-circle .p-speeddial-item, .p-speeddial-semi-circle .p-speeddial-item, .p-speeddial-quarter-circle .p-speeddial-item {
    margin: 0
}

.p-speeddial-circle .p-speeddial-item:first-child, .p-speeddial-circle .p-speeddial-item:last-child, .p-speeddial-semi-circle .p-speeddial-item:first-child, .p-speeddial-semi-circle .p-speeddial-item:last-child, .p-speeddial-quarter-circle .p-speeddial-item:first-child, .p-speeddial-quarter-circle .p-speeddial-item:last-child {
    margin: 0
}

.p-speeddial-mask {
    background-color: rgba(0, 0, 0, .4)
}

.p-splitbutton {
    border-radius: 6px
}

.p-splitbutton.p-button-outlined > .p-button {
    background-color: transparent;
    color: #383097;
    border: 2px solid
}

.p-splitbutton.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(56, 48, 151, .04);
    color: #383097
}

.p-splitbutton.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(56, 48, 151, .16);
    color: #383097
}

.p-splitbutton.p-button-outlined.p-button-plain > .p-button {
    color: #7267b4;
    border-color: #7267b4
}

.p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:hover, .p-splitbutton.p-button-outlined.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: #f7f6fb;
    color: #7267b4
}

.p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:active, .p-splitbutton.p-button-outlined.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):active {
    background: #f7f6fb;
    color: #7267b4
}

.p-splitbutton.p-button-text > .p-button {
    background-color: transparent;
    color: #383097;
    border-color: transparent
}

.p-splitbutton.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(56, 48, 151, .04);
    color: #383097;
    border-color: transparent
}

.p-splitbutton.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(56, 48, 151, .16);
    color: #383097;
    border-color: transparent
}

.p-splitbutton.p-button-text.p-button-plain > .p-button {
    color: #7267b4
}

.p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:hover, .p-splitbutton.p-button-text.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: #f7f6fb;
    color: #7267b4
}

.p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:active, .p-splitbutton.p-button-text.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):active {
    background: #f7f6fb;
    color: #7267b4
}

.p-splitbutton.p-button-raised {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.p-splitbutton.p-button-rounded {
    border-radius: 2rem
}

.p-splitbutton.p-button-rounded > .p-button {
    border-radius: 2rem
}

.p-splitbutton.p-button-sm > .p-button {
    font-size: .875rem;
    padding: .4375rem .875rem
}

.p-splitbutton.p-button-sm > .p-button .p-button-icon {
    font-size: .875rem
}

.p-splitbutton.p-button-lg > .p-button {
    font-size: 1.25rem;
    padding: .625rem 1.25rem
}

.p-splitbutton.p-button-lg > .p-button .p-button-icon {
    font-size: 1.25rem
}

.p-splitbutton.p-button-secondary.p-button-outlined > .p-button {
    background-color: transparent;
    color: #dd3636;
    border: 2px solid
}

.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-secondary.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(221, 54, 54, .04);
    color: #dd3636
}

.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-secondary.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(221, 54, 54, .16);
    color: #dd3636
}

.p-splitbutton.p-button-secondary.p-button-text > .p-button {
    background-color: transparent;
    color: #dd3636;
    border-color: transparent
}

.p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-secondary.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(221, 54, 54, .04);
    border-color: transparent;
    color: #dd3636
}

.p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-secondary.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(221, 54, 54, .16);
    border-color: transparent;
    color: #dd3636
}

.p-splitbutton.p-button-info.p-button-outlined > .p-button {
    background-color: transparent;
    color: #276ef1;
    border: 2px solid
}

.p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-info.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(39, 110, 241, .04);
    color: #276ef1
}

.p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-info.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(39, 110, 241, .16);
    color: #276ef1
}

.p-splitbutton.p-button-info.p-button-text > .p-button {
    background-color: transparent;
    color: #276ef1;
    border-color: transparent
}

.p-splitbutton.p-button-info.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-info.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(39, 110, 241, .04);
    border-color: transparent;
    color: #276ef1
}

.p-splitbutton.p-button-info.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-info.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(39, 110, 241, .16);
    border-color: transparent;
    color: #276ef1
}

.p-splitbutton.p-button-success.p-button-outlined > .p-button {
    background-color: transparent;
    color: #3aa76d;
    border: 2px solid
}

.p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-success.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(58, 167, 109, .04);
    color: #3aa76d
}

.p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-success.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(58, 167, 109, .16);
    color: #3aa76d
}

.p-splitbutton.p-button-success.p-button-text > .p-button {
    background-color: transparent;
    color: #3aa76d;
    border-color: transparent
}

.p-splitbutton.p-button-success.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-success.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(58, 167, 109, .04);
    border-color: transparent;
    color: #3aa76d
}

.p-splitbutton.p-button-success.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-success.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(58, 167, 109, .16);
    border-color: transparent;
    color: #3aa76d
}

.p-splitbutton.p-button-warning.p-button-outlined > .p-button {
    background-color: transparent;
    color: #ff922a;
    border: 2px solid
}

.p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-warning.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(255, 146, 42, .04);
    color: #ff922a
}

.p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-warning.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(255, 146, 42, .16);
    color: #ff922a
}

.p-splitbutton.p-button-warning.p-button-text > .p-button {
    background-color: transparent;
    color: #ff922a;
    border-color: transparent
}

.p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-warning.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(255, 146, 42, .04);
    border-color: transparent;
    color: #ff922a
}

.p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-warning.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(255, 146, 42, .16);
    border-color: transparent;
    color: #ff922a
}

.p-splitbutton.p-button-help.p-button-outlined > .p-button {
    background-color: transparent;
    color: #276ef1;
    border: 2px solid
}

.p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-help.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(39, 110, 241, .04);
    color: #276ef1
}

.p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-help.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(39, 110, 241, .16);
    color: #276ef1
}

.p-splitbutton.p-button-help.p-button-text > .p-button {
    background-color: transparent;
    color: #276ef1;
    border-color: transparent
}

.p-splitbutton.p-button-help.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-help.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(39, 110, 241, .04);
    border-color: transparent;
    color: #276ef1
}

.p-splitbutton.p-button-help.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-help.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(39, 110, 241, .16);
    border-color: transparent;
    color: #276ef1
}

.p-splitbutton.p-button-danger.p-button-outlined > .p-button {
    background-color: transparent;
    color: #dd3636;
    border: 2px solid
}

.p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-danger.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(221, 54, 54, .04);
    color: #dd3636
}

.p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-danger.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(221, 54, 54, .16);
    color: #dd3636
}

.p-splitbutton.p-button-danger.p-button-text > .p-button {
    background-color: transparent;
    color: #dd3636;
    border-color: transparent
}

.p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-danger.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(221, 54, 54, .04);
    border-color: transparent;
    color: #dd3636
}

.p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-danger.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(221, 54, 54, .16);
    border-color: transparent;
    color: #dd3636
}

.p-carousel .p-carousel-content .p-carousel-prev, .p-carousel .p-carousel-content .p-carousel-next {
    width: 2rem;
    height: 2rem;
    color: #7267b4;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color .3s, color .3s, box-shadow .3s;
    margin: .5rem
}

.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover, .p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
    color: #383097;
    border-color: transparent;
    background: #f7f6fb
}

.p-carousel .p-carousel-content .p-carousel-prev:focus, .p-carousel .p-carousel-content .p-carousel-next:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-carousel .p-carousel-indicators {
    padding: 1rem
}

.p-carousel .p-carousel-indicators .p-carousel-indicator {
    margin-right: .5rem;
    margin-bottom: .5rem
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
    background-color: #f7f6fb;
    width: 2rem;
    height: .5rem;
    transition: background-color .3s, color .3s, box-shadow .3s;
    border-radius: 0
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
    background: #f7f6fb
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background: #383097;
    color: #fff
}

.p-datatable .p-paginator-top {
    border-width: 0 0 2px 0;
    border-radius: 0
}

.p-datatable .p-paginator-bottom {
    border-width: 0 0 2px 0;
    border-radius: 0
}

.p-datatable .p-datatable-header {
    background: #fff;
    color: #383097;
    border: 2px solid #f3f2f7;
    border-width: 0 0 2px 0;
    padding: 1rem 1rem;
    font-weight: 100
}

.p-datatable .p-datatable-footer {
    background: #fff;
    color: #383097;
    border: 2px solid #f3f2f7;
    border-width: 0 0 2px 0;
    padding: 1rem 1rem;
    font-weight: 100
}

.p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 2px solid #f3f2f7;
    border-width: 0 0 2px 0;
    font-weight: 100;
    color: #383097;
    background: #fff;
    transition: box-shadow .3s
}

.p-datatable .p-datatable-tfoot > tr > td {
    text-align: left;
    padding: 1rem 1rem;
    border: 2px solid #f3f2f7;
    border-width: 0 0 2px 0;
    font-weight: 100;
    color: #383097;
    background: #fff
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #7267b4;
    margin-left: .5rem
}

.p-datatable .p-sortable-column .p-sortable-column-badge {
    border-radius: 50%;
    height: 1.143rem;
    min-width: 1.143rem;
    line-height: 1.143rem;
    color: #fff;
    background: #383097;
    margin-left: .5rem
}

.p-datatable .p-sortable-column:not(.p-highlight):hover {
    background: #f7f6fb;
    color: #383097
}

.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    color: #7267b4
}

.p-datatable .p-sortable-column.p-highlight {
    background: #fff;
    color: #383097
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #383097
}

.p-datatable .p-sortable-column.p-highlight:hover {
    background: #f7f6fb;
    color: #383097
}

.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
    color: #383097
}

.p-datatable .p-sortable-column:focus {
    box-shadow: inset 0 0 0 .15rem #a6a1e1;
    outline: 0 none
}

.p-datatable .p-datatable-tbody > tr {
    background: #fff;
    color: #383097;
    transition: box-shadow .3s
}

.p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 2px solid #f3f2f7;
    border-width: 0 0 2px 0;
    padding: 1rem 1rem
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
    width: 2rem;
    height: 2rem;
    color: #7267b4;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color .3s, color .3s, box-shadow .3s
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
    color: #383097;
    border-color: transparent;
    background: #f7f6fb
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
    margin-right: .5rem
}

.p-datatable .p-datatable-tbody > tr:focus {
    outline: .15rem solid #a6a1e1;
    outline-offset: .15rem
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
    background: #383097;
    color: #fff
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
    box-shadow: inset 0 2px 0 0 #383097
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
    box-shadow: inset 0 -2px 0 0 #383097
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
    background: #f7f6fb;
    color: #383097
}

.p-datatable .p-column-resizer-helper {
    background: #383097
}

.p-datatable .p-datatable-scrollable-header, .p-datatable .p-datatable-scrollable-footer {
    background: #fff
}

.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead, .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-tfoot {
    background-color: #fff
}

.p-datatable .p-datatable-loading-icon {
    font-size: 2rem
}

.p-datatable.p-datatable-gridlines .p-datatable-header {
    border-width: 1px 1px 0 1px
}

.p-datatable.p-datatable-gridlines .p-datatable-footer {
    border-width: 0 1px 1px 1px
}

.p-datatable.p-datatable-gridlines .p-paginator-top {
    border-width: 0 1px 0 1px
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
    border-width: 0 1px 1px 1px
}

.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
    border-width: 1px 1px 2px 1px
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
    border-width: 1px
}

.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
    border-width: 1px
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
    background: #fcfcfc
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
    background: #383097;
    color: #fff
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
    color: #fff
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
    color: #fff
}

.p-datatable.p-datatable-sm .p-datatable-header {
    padding: .5rem .5rem
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
    padding: .5rem .5rem
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    padding: .5rem .5rem
}

.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
    padding: .5rem .5rem
}

.p-datatable.p-datatable-sm .p-datatable-footer {
    padding: .5rem .5rem
}

.p-datatable.p-datatable-lg .p-datatable-header {
    padding: 1.25rem 1.25rem
}

.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
    padding: 1.25rem 1.25rem
}

.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
    padding: 1.25rem 1.25rem
}

.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
    padding: 1.25rem 1.25rem
}

.p-datatable.p-datatable-lg .p-datatable-footer {
    padding: 1.25rem 1.25rem
}

.p-dataview .p-paginator-top {
    border-width: 0 0 2px 0;
    border-radius: 0
}

.p-dataview .p-paginator-bottom {
    border-width: 0 0 2px 0;
    border-radius: 0
}

.p-dataview .p-dataview-header {
    background: #fff;
    color: #383097;
    border: 2px solid #f3f2f7;
    border-width: 0 0 2px 0;
    padding: 1rem 1rem;
    font-weight: 100
}

.p-dataview .p-dataview-content {
    background: #fff;
    color: #383097;
    border: 0 none;
    padding: 0
}

.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
    border: solid #f3f2f7;
    border-width: 0 0 1px 0
}

.p-dataview .p-dataview-footer {
    background: #fff;
    color: #383097;
    border: 2px solid #f3f2f7;
    border-width: 0 0 2px 0;
    padding: 1rem 1rem;
    font-weight: 100;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px
}

.p-dataview .p-dataview-loading-icon {
    font-size: 2rem
}

.p-dataview .p-dataview-emptymessage {
    padding: 1rem
}

.p-column-filter-row .p-column-filter-menu-button, .p-column-filter-row .p-column-filter-clear-button {
    margin-left: .5rem
}

.p-column-filter-menu-button {
    width: 2rem;
    height: 2rem;
    color: #7267b4;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color .3s, color .3s, box-shadow .3s
}

.p-column-filter-menu-button:hover {
    color: #383097;
    border-color: transparent;
    background: #f7f6fb
}

.p-column-filter-menu-button.p-column-filter-menu-button-open, .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
    background: #f7f6fb;
    color: #383097
}

.p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
    background: #383097;
    color: #fff
}

.p-column-filter-menu-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-column-filter-clear-button {
    width: 2rem;
    height: 2rem;
    color: #7267b4;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color .3s, color .3s, box-shadow .3s
}

.p-column-filter-clear-button:hover {
    color: #383097;
    border-color: transparent;
    background: #f7f6fb
}

.p-column-filter-clear-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-column-filter-overlay {
    background: #fff;
    color: #383097;
    border: 0 none;
    border-radius: 6px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    min-width: 12.5rem
}

.p-column-filter-overlay .p-column-filter-row-items {
    padding: .5rem .5rem
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
    margin: 0 0 4px 0;
    padding: .5rem 1rem;
    border: 0 none;
    color: #383097;
    background: transparent;
    transition: box-shadow .3s;
    border-radius: 6px
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
    color: #fff;
    background: #383097
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
    color: #383097;
    background: #f7f6fb
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #a6a1e1
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
    border-top: 1px solid #f7f6fb;
    margin: 4px 0
}

.p-column-filter-overlay-menu .p-column-filter-operator {
    padding: .5rem 1.5rem;
    border-bottom: 0 none;
    color: #383097;
    background: #fff;
    margin: 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px
}

.p-column-filter-overlay-menu .p-column-filter-constraint {
    padding: 1rem;
    border-bottom: 1px solid #f7f6fb
}

.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
    margin-bottom: .5rem
}

.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
    margin-top: .5rem
}

.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
    border-bottom: 0 none
}

.p-column-filter-overlay-menu .p-column-filter-add-rule {
    padding: .5rem 1rem
}

.p-column-filter-overlay-menu .p-column-filter-buttonbar {
    padding: 1rem
}

.fc.fc-unthemed .fc-view-container th {
    background: #fff;
    border: 2px solid #f7f6fb;
    color: #383097
}

.fc.fc-unthemed .fc-view-container td.fc-widget-content {
    background: #fff;
    border: 2px solid #f7f6fb;
    color: #383097
}

.fc.fc-unthemed .fc-view-container td.fc-head-container {
    border: 2px solid #f7f6fb
}

.fc.fc-unthemed .fc-view-container .fc-row {
    border-right: 2px solid #f7f6fb
}

.fc.fc-unthemed .fc-view-container .fc-event {
    background: #352e8f;
    border: 2px solid #352e8f;
    color: #fff
}

.fc.fc-unthemed .fc-view-container .fc-divider {
    background: #fff;
    border: 2px solid #f7f6fb
}

.fc.fc-unthemed .fc-toolbar .fc-button {
    color: #fff;
    background: #383097;
    border: 2px solid #383097;
    font-size: 1rem;
    transition: background-color .3s, color .3s, border-color .3s, box-shadow .3s;
    border-radius: 6px;
    display: flex;
    align-items: center
}

.fc.fc-unthemed .fc-toolbar .fc-button:enabled:hover {
    background: #352e8f;
    color: #fff;
    border-color: #352e8f
}

.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active {
    background: #322b88;
    color: #fff;
    border-color: #322b88
}

.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left {
    font-family: "PrimeIcons" !important;
    text-indent: 0;
    font-size: 1rem
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left:before {
    content: ""
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right {
    font-family: "PrimeIcons" !important;
    text-indent: 0;
    font-size: 1rem
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right:before {
    content: ""
}

.fc.fc-unthemed .fc-toolbar .fc-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button {
    background: #fff;
    border: 2px solid #eeedf2;
    color: #383097;
    transition: background-color .3s, color .3s, border-color .3s, box-shadow .3s
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
    background: #f7f6fb;
    border-color: #eeedf2;
    color: #383097
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
    background: #383097;
    border-color: #383097;
    color: #fff
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
    background: #352e8f;
    border-color: #352e8f;
    color: #fff
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:focus, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:focus, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1;
    z-index: 1
}

.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button {
    border-radius: 0
}

.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px
}

.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px
}

.fc.fc-theme-standard .fc-view-harness .fc-scrollgrid {
    border-color: #f7f6fb
}

.fc.fc-theme-standard .fc-view-harness th {
    background: #fff;
    border-color: #f7f6fb;
    color: #383097
}

.fc.fc-theme-standard .fc-view-harness td {
    color: #383097;
    border-color: #f7f6fb
}

.fc.fc-theme-standard .fc-view-harness .fc-view {
    background: #fff
}

.fc.fc-theme-standard .fc-view-harness .fc-popover {
    background: none;
    border: 0 none
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header {
    border: 2px solid #f7f6fb;
    padding: 1rem;
    background: #fff;
    color: #383097
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close {
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-family: "PrimeIcons" !important;
    font-size: 1rem;
    width: 2rem;
    height: 2rem;
    color: #7267b4;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color .3s, color .3s, box-shadow .3s
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:before {
    content: ""
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:enabled:hover {
    color: #383097;
    border-color: transparent;
    background: #f7f6fb
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-body {
    padding: 1rem;
    border: 2px solid #f7f6fb;
    background: #fff;
    color: #383097;
    border-top: 0 none
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event {
    color: #fff;
    background: #352e8f;
    border-color: #352e8f
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event .fc-event-main {
    color: #fff
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event .fc-daygrid-event-dot {
    background: #352e8f;
    border-color: #352e8f
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event:hover {
    background: #f7f6fb;
    color: #383097
}

.fc.fc-theme-standard .fc-view-harness .fc-cell-shaded {
    background: #fff
}

.fc.fc-theme-standard .fc-toolbar .fc-button {
    color: #fff;
    background: #383097;
    border: 2px solid #383097;
    font-size: 1rem;
    transition: background-color .3s, color .3s, border-color .3s, box-shadow .3s;
    border-radius: 6px
}

.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:hover {
    background: #352e8f;
    color: #fff;
    border-color: #352e8f
}

.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active {
    background: #322b88;
    color: #fff;
    border-color: #322b88
}

.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.fc.fc-theme-standard .fc-toolbar .fc-button:disabled {
    opacity: .6;
    color: #fff;
    background: #383097;
    border: 2px solid #383097
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left {
    font-family: "PrimeIcons" !important;
    text-indent: 0;
    font-size: 1rem
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left:before {
    content: ""
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right {
    font-family: "PrimeIcons" !important;
    text-indent: 0;
    font-size: 1rem
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right:before {
    content: ""
}

.fc.fc-theme-standard .fc-toolbar .fc-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button {
    background: #fff;
    border: 2px solid #eeedf2;
    color: #383097;
    transition: background-color .3s, color .3s, border-color .3s, box-shadow .3s
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
    background: #f7f6fb;
    border-color: #eeedf2;
    color: #383097
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
    background: #383097;
    border-color: #383097;
    color: #fff
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
    background: #352e8f;
    border-color: #352e8f;
    color: #fff
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:not(:disabled):focus, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:not(:disabled):focus, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:not(:disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1;
    z-index: 1
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button {
    border-radius: 0
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px
}

.fc.fc-theme-standard a {
    color: #383097
}

.fc.fc-theme-standard .fc-highlight {
    color: #fff;
    background: #383097
}

.p-orderlist .p-orderlist-controls {
    padding: 1rem
}

.p-orderlist .p-orderlist-controls .p-button {
    margin-bottom: .5rem
}

.p-orderlist .p-orderlist-header {
    background: #fff;
    color: #383097;
    border: 2px solid #f7f6fb;
    padding: 1rem;
    border-bottom: 0 none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px
}

.p-orderlist .p-orderlist-header .p-orderlist-title {
    font-weight: 100
}

.p-orderlist .p-orderlist-filter-container {
    padding: 1rem;
    background: #fff;
    border: 2px solid #f7f6fb;
    border-bottom: 0 none
}

.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
    padding-right: 1.75rem
}

.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
    right: .75rem;
    color: #7267b4
}

.p-orderlist .p-orderlist-list {
    border: 2px solid #f7f6fb;
    background: #fff;
    color: #383097;
    padding: .5rem .5rem;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px
}

.p-orderlist .p-orderlist-list .p-orderlist-item {
    padding: .5rem 1rem;
    margin: 0 0 4px 0;
    border: 0 none;
    color: #383097;
    background: transparent;
    transition: box-shadow .3s
}

.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #a6a1e1
}

.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
    color: #fff;
    background: #383097
}

.p-orderlist .p-orderlist-list .p-orderlist-empty-message {
    padding: .5rem 1rem;
    color: #383097
}

.p-orderlist .p-orderlist-list:not(.cdk-drop-list-dragging) .p-orderlist-item:not(.p-highlight):hover {
    background: #f7f6fb;
    color: #383097
}

.p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even) {
    background: #fcfcfc
}

.p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even):hover {
    background: #f7f6fb
}

.p-orderlist-item.cdk-drag-preview {
    padding: .5rem 1rem;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    border: 0 none;
    color: #383097;
    background: #fff;
    margin: 0
}

.p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
    background: #f7f6fb;
    color: #383097
}

.p-organizationchart .p-organizationchart-node-content.p-highlight {
    background: #383097;
    color: #fff
}

.p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
    color: #141136
}

.p-organizationchart .p-organizationchart-line-down {
    background: #f7f6fb
}

.p-organizationchart .p-organizationchart-line-left {
    border-right: 2px solid #f7f6fb;
    border-color: #f7f6fb
}

.p-organizationchart .p-organizationchart-line-top {
    border-top: 2px solid #f7f6fb;
    border-color: #f7f6fb
}

.p-organizationchart .p-organizationchart-node-content {
    border: 2px solid #f7f6fb;
    background: #fff;
    color: #383097;
    padding: 1rem
}

.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
    background: inherit;
    color: inherit;
    border-radius: 50%
}

.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-paginator {
    background: #fff;
    color: #7267b4;
    border: solid #f7f6fb;
    border-width: 2px;
    padding: .5rem 1rem;
    border-radius: 6px
}

.p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
    background-color: transparent;
    border: 0 none;
    color: #7267b4;
    min-width: 2.857rem;
    height: 2.857rem;
    margin: .143rem;
    transition: box-shadow .3s;
    border-radius: 6px
}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    background: #f7f6fb;
    border-color: transparent;
    color: #383097
}

.p-paginator .p-paginator-first {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px
}

.p-paginator .p-paginator-last {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px
}

.p-paginator .p-dropdown {
    margin-left: .5rem;
    margin-right: .5rem;
    height: 2.857rem
}

.p-paginator .p-dropdown .p-dropdown-label {
    padding-right: 0
}

.p-paginator .p-paginator-page-input {
    margin-left: .5rem;
    margin-right: .5rem
}

.p-paginator .p-paginator-page-input .p-inputtext {
    max-width: 2.857rem
}

.p-paginator .p-paginator-current {
    background-color: transparent;
    border: 0 none;
    color: #7267b4;
    min-width: 2.857rem;
    height: 2.857rem;
    margin: .143rem;
    padding: 0 .5rem
}

.p-paginator .p-paginator-pages .p-paginator-page {
    background-color: transparent;
    border: 0 none;
    color: #7267b4;
    min-width: 2.857rem;
    height: 2.857rem;
    margin: .143rem;
    transition: box-shadow .3s;
    border-radius: 6px
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #383097;
    border-color: #383097;
    color: #fff
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    background: #f7f6fb;
    border-color: transparent;
    color: #383097
}

.p-picklist .p-picklist-buttons {
    padding: 1rem
}

.p-picklist .p-picklist-buttons .p-button {
    margin-bottom: .5rem
}

.p-picklist .p-picklist-header {
    background: #fff;
    color: #383097;
    border: 2px solid #f7f6fb;
    padding: 1rem;
    border-bottom: 0 none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px
}

.p-picklist .p-picklist-header .p-picklist-title {
    font-weight: 100
}

.p-picklist .p-picklist-filter-container {
    padding: 1rem;
    background: #fff;
    border: 2px solid #f7f6fb;
    border-bottom: 0 none
}

.p-picklist .p-picklist-filter-container .p-picklist-filter-input {
    padding-right: 1.75rem
}

.p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
    right: .75rem;
    color: #7267b4
}

.p-picklist .p-picklist-list {
    border: 2px solid #f7f6fb;
    background: #fff;
    color: #383097;
    padding: .5rem .5rem;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px
}

.p-picklist .p-picklist-list .p-picklist-item {
    padding: .5rem 1rem;
    margin: 0 0 4px 0;
    border: 0 none;
    color: #383097;
    background: transparent;
    transition: box-shadow .3s
}

.p-picklist .p-picklist-list .p-picklist-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #a6a1e1
}

.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
    color: #fff;
    background: #383097
}

.p-picklist .p-picklist-list .p-picklist-empty-message {
    padding: .5rem 1rem;
    color: #383097
}

.p-picklist .p-picklist-list:not(.cdk-drop-list-dragging) .p-picklist-item:not(.p-highlight):hover {
    background: #f7f6fb;
    color: #383097
}

.p-picklist.p-picklist-striped .p-picklist-list .p-picklist-item:nth-child(even) {
    background: #fcfcfc
}

.p-picklist.p-picklist-striped .p-picklist-list .p-picklist-item:nth-child(even):hover {
    background: #f7f6fb
}

.p-picklist-item.cdk-drag-preview {
    padding: .5rem 1rem;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    border: 0 none;
    color: #383097;
    background: #fff;
    margin: 0
}

.p-timeline .p-timeline-event-marker {
    border: 2px solid #383097;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    background-color: #fff
}

.p-timeline .p-timeline-event-connector {
    background-color: #f7f6fb
}

.p-timeline.p-timeline-vertical .p-timeline-event-opposite, .p-timeline.p-timeline-vertical .p-timeline-event-content {
    padding: 0 1rem
}

.p-timeline.p-timeline-vertical .p-timeline-event-connector {
    width: 2px
}

.p-timeline.p-timeline-horizontal .p-timeline-event-opposite, .p-timeline.p-timeline-horizontal .p-timeline-event-content {
    padding: 1rem 0
}

.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
    height: 2px
}

.p-tree {
    border: 2px solid #f7f6fb;
    background: #fff;
    color: #383097;
    padding: 1rem;
    border-radius: 6px
}

.p-tree .p-tree-container .p-treenode {
    padding: .143rem
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
    border-radius: 6px;
    transition: box-shadow .3s;
    padding: .5rem
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
    margin-right: .5rem;
    width: 2rem;
    height: 2rem;
    color: #7267b4;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color .3s, color .3s, box-shadow .3s
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
    color: #383097;
    border-color: transparent;
    background: #f7f6fb
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
    margin-right: .5rem;
    color: #7267b4
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
    margin-right: .5rem
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
    color: #383097
}

.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #a6a1e1
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background: #383097;
    color: #fff
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler, .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
    color: #fff
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover, .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
    color: #fff
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    background: #f7f6fb;
    color: #383097
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
    background: #f7f6fb;
    color: #383097
}

.p-tree .p-tree-filter-container {
    margin-bottom: .5rem
}

.p-tree .p-tree-filter-container .p-tree-filter {
    width: 100%;
    padding-right: 1.75rem
}

.p-tree .p-tree-filter-container .p-tree-filter-icon {
    right: .75rem;
    color: #7267b4
}

.p-tree .p-treenode-children {
    padding: 0 0 0 1rem
}

.p-tree .p-tree-loading-icon {
    font-size: 2rem
}

.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
    background-color: #2d2679
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
    border-radius: 6px;
    border: 2px solid #f7f6fb;
    background-color: #fff;
    color: #383097;
    padding: .5rem;
    transition: box-shadow .3s
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
    background-color: #383097;
    color: #fff
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
    color: #fff
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {
    margin-right: .5rem
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {
    color: #7267b4;
    margin-right: .5rem
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {
    margin-right: .5rem
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-highlight):hover {
    background-color: inherit;
    color: inherit
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    background: #f7f6fb;
    color: #383097
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-treetable .p-paginator-top {
    border-width: 0 0 2px 0;
    border-radius: 0
}

.p-treetable .p-paginator-bottom {
    border-width: 0 0 2px 0;
    border-radius: 0
}

.p-treetable .p-treetable-header {
    background: #fff;
    color: #383097;
    border: 2px solid #f3f2f7;
    border-width: 0 0 2px 0;
    padding: 1rem 1rem;
    font-weight: 100
}

.p-treetable .p-treetable-footer {
    background: #fff;
    color: #383097;
    border: 2px solid #f3f2f7;
    border-width: 0 0 2px 0;
    padding: 1rem 1rem;
    font-weight: 100
}

.p-treetable .p-treetable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 2px solid #f3f2f7;
    border-width: 0 0 2px 0;
    font-weight: 100;
    color: #383097;
    background: #fff;
    transition: box-shadow .3s
}

.p-treetable .p-treetable-tfoot > tr > td {
    text-align: left;
    padding: 1rem 1rem;
    border: 2px solid #f3f2f7;
    border-width: 0 0 2px 0;
    font-weight: 100;
    color: #383097;
    background: #fff
}

.p-treetable .p-sortable-column {
    outline-color: #a6a1e1
}

.p-treetable .p-sortable-column .p-sortable-column-icon {
    color: #7267b4;
    margin-left: .5rem
}

.p-treetable .p-sortable-column .p-sortable-column-badge {
    border-radius: 50%;
    height: 1.143rem;
    min-width: 1.143rem;
    line-height: 1.143rem;
    color: #fff;
    background: #383097;
    margin-left: .5rem
}

.p-treetable .p-sortable-column:not(.p-highlight):hover {
    background: #f7f6fb;
    color: #383097
}

.p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    color: #7267b4
}

.p-treetable .p-sortable-column.p-highlight {
    background: #fff;
    color: #383097
}

.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #383097
}

.p-treetable .p-treetable-tbody > tr {
    background: #fff;
    color: #383097;
    transition: box-shadow .3s
}

.p-treetable .p-treetable-tbody > tr > td {
    text-align: left;
    border: 2px solid #f3f2f7;
    border-width: 0 0 2px 0;
    padding: 1rem 1rem
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
    width: 2rem;
    height: 2rem;
    color: #7267b4;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color .3s, color .3s, box-shadow .3s;
    margin-right: .5rem
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
    color: #383097;
    border-color: transparent;
    background: #f7f6fb
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox {
    margin-right: .5rem
}

.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox .p-indeterminate .p-checkbox-icon {
    color: #383097
}

.p-treetable .p-treetable-tbody > tr:focus {
    outline: .15rem solid #a6a1e1;
    outline-offset: -0.15rem
}

.p-treetable .p-treetable-tbody > tr.p-highlight {
    background: #383097;
    color: #fff
}

.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
    color: #fff
}

.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
    color: #fff
}

.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
    background: #f7f6fb;
    color: #383097
}

.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
    color: #383097
}

.p-treetable .p-column-resizer-helper {
    background: #383097
}

.p-treetable .p-treetable-scrollable-header, .p-treetable .p-treetable-scrollable-footer {
    background: #fff
}

.p-treetable .p-treetable-loading-icon {
    font-size: 2rem
}

.p-treetable.p-treetable-gridlines .p-datatable-header {
    border-width: 1px 1px 0 1px
}

.p-treetable.p-treetable-gridlines .p-treetable-footer {
    border-width: 0 1px 1px 1px
}

.p-treetable.p-treetable-gridlines .p-treetable-top {
    border-width: 0 1px 0 1px
}

.p-treetable.p-treetable-gridlines .p-treetable-bottom {
    border-width: 0 1px 1px 1px
}

.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
    border-width: 1px
}

.p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
    border-width: 1px
}

.p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
    border-width: 1px
}

.p-treetable.p-treetable-sm .p-treetable-header {
    padding: .875rem .875rem
}

.p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
    padding: .5rem .5rem
}

.p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
    padding: .5rem .5rem
}

.p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
    padding: .5rem .5rem
}

.p-treetable.p-treetable-sm .p-treetable-footer {
    padding: .5rem .5rem
}

.p-treetable.p-treetable-lg .p-treetable-header {
    padding: 1.25rem 1.25rem
}

.p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
    padding: 1.25rem 1.25rem
}

.p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
    padding: 1.25rem 1.25rem
}

.p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
    padding: 1.25rem 1.25rem
}

.p-treetable.p-treetable-lg .p-treetable-footer {
    padding: 1.25rem 1.25rem
}

.p-virtualscroller .p-virtualscroller-header {
    background: #fff;
    color: #383097;
    border: 2px solid #f3f2f7;
    border-width: 0 0 2px 0;
    padding: 1rem 1rem;
    font-weight: 100
}

.p-virtualscroller .p-virtualscroller-content {
    background: #fff;
    color: #383097;
    border: 0 none;
    padding: 0
}

.p-virtualscroller .p-virtualscroller-footer {
    background: #fff;
    color: #383097;
    border: 2px solid #f3f2f7;
    border-width: 0 0 2px 0;
    padding: 1rem 1rem;
    font-weight: 100;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 1rem;
    border: 2px solid #f7f6fb;
    color: #383097;
    background: #fff;
    font-weight: 100;
    border-radius: 6px;
    transition: box-shadow .3s
}

.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
    margin-right: .5rem
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
    background: #f7f6fb;
    border-color: #f7f6fb;
    color: #383097
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background: #fff;
    border-color: #f7f6fb;
    color: #383097;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    border-color: #f7f6fb;
    background: #f7f6fb;
    color: #383097
}

.p-accordion .p-accordion-content {
    padding: 1rem;
    border: 2px solid #f7f6fb;
    background: #fff;
    color: #383097;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px
}

.p-accordion p-accordiontab .p-accordion-tab {
    margin-bottom: 0
}

.p-accordion p-accordiontab .p-accordion-header .p-accordion-header-link {
    border-radius: 0
}

.p-accordion p-accordiontab .p-accordion-content {
    border-radius: 0
}

.p-accordion p-accordiontab:not(:first-child) .p-accordion-header .p-accordion-header-link {
    border-top: 0 none
}

.p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link, .p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    border-top: 0 none
}

.p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px
}

.p-accordion p-accordiontab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px
}

.p-accordion p-accordiontab:last-child .p-accordion-content {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px
}

.p-card {
    background: #fff;
    color: #383097;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    border-radius: 6px
}

.p-card .p-card-body {
    padding: 1rem
}

.p-card .p-card-title {
    font-size: 1.5rem;
    font-weight: 100;
    margin-bottom: .5rem
}

.p-card .p-card-subtitle {
    font-weight: 400;
    margin-bottom: .5rem;
    color: #7267b4
}

.p-card .p-card-content {
    padding: 1rem 0
}

.p-card .p-card-footer {
    padding: 1rem 0 0 0
}

.p-divider .p-divider-content {
    background-color: #fff
}

.p-divider.p-divider-horizontal {
    margin: 1rem 0;
    padding: 0 1rem
}

.p-divider.p-divider-horizontal:before {
    border-top: 1px #f7f6fb
}

.p-divider.p-divider-horizontal .p-divider-content {
    padding: 0 .5rem
}

.p-divider.p-divider-vertical {
    margin: 0 1rem;
    padding: 1rem 0
}

.p-divider.p-divider-vertical:before {
    border-left: 1px #f7f6fb
}

.p-divider.p-divider-vertical .p-divider-content {
    padding: .5rem 0
}

.p-fieldset {
    border: 2px solid #f7f6fb;
    background: #fff;
    color: #383097;
    border-radius: 6px
}

.p-fieldset .p-fieldset-legend {
    padding: 1rem;
    border: 2px solid #f7f6fb;
    color: #383097;
    background: #fff;
    font-weight: 100;
    border-radius: 6px
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
    padding: 0;
    transition: background-color .3s, color .3s, box-shadow .3s
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
    padding: 1rem;
    color: #383097;
    border-radius: 6px;
    transition: box-shadow .3s
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
    margin-right: .5rem
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
    background: #f7f6fb;
    border-color: #f7f6fb;
    color: #383097
}

.p-fieldset .p-fieldset-content {
    padding: 1rem
}

.p-panel .p-panel-header {
    border: 2px solid #f7f6fb;
    padding: 1rem;
    background: #fff;
    color: #383097;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px
}

.p-panel .p-panel-header .p-panel-title {
    font-weight: 100
}

.p-panel .p-panel-header .p-panel-header-icon {
    width: 2rem;
    height: 2rem;
    color: #7267b4;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color .3s, color .3s, box-shadow .3s
}

.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
    color: #383097;
    border-color: transparent;
    background: #f7f6fb
}

.p-panel .p-panel-header .p-panel-header-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-panel.p-panel-toggleable .p-panel-header {
    padding: .5rem 1rem
}

.p-panel .p-panel-content {
    padding: 1rem;
    border: 2px solid #f7f6fb;
    background: #fff;
    color: #383097;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top: 0 none
}

.p-panel .p-panel-footer {
    padding: .5rem 1rem;
    border: 2px solid #f7f6fb;
    background: #fff;
    color: #383097;
    border-top: 0 none
}

.p-panel .p-panel-icons-end {
    order: 2;
    margin-left: auto
}

.p-panel .p-panel-icons-start {
    order: 0;
    margin-right: .5rem
}

.p-panel .p-panel-icons-center {
    order: 2;
    width: 100%;
    text-align: center
}

.p-scrollpanel .p-scrollpanel-bar {
    background: #f3f2f7;
    border: 0 none
}

.p-splitter {
    border: 2px solid #f7f6fb;
    background: #fff;
    border-radius: 6px;
    color: #383097
}

.p-splitter .p-splitter-gutter {
    transition: background-color .3s, color .3s, box-shadow .3s;
    background: #f3f2f7
}

.p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
    background: #f7f6fb
}

.p-splitter .p-splitter-gutter-resizing {
    background: #f7f6fb
}

.p-tabview .p-tabview-nav {
    background: #fff;
    border: 1px solid #f7f6fb;
    border-width: 0 0 2px 0
}

.p-tabview .p-tabview-nav li {
    margin-right: 0
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border: solid #f7f6fb;
    border-width: 0 0 2px 0;
    border-color: transparent transparent #f7f6fb transparent;
    background: #fff;
    color: #7267b4;
    padding: 1rem;
    font-weight: 100;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    transition: box-shadow .3s;
    margin: 0 0 -2px 0
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .1rem #a6a1e1
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    background: #fff;
    border-color: #383097;
    color: #7267b4
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #fff;
    border-color: #383097;
    color: #383097
}

.p-tabview .p-tabview-left-icon {
    margin-right: .5rem
}

.p-tabview .p-tabview-right-icon {
    margin-left: .5rem
}

.p-tabview .p-tabview-close {
    margin-left: .5rem
}

.p-tabview .p-tabview-nav-btn.p-link {
    background: #fff;
    color: #383097;
    width: 2.857rem;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
    border-radius: 0
}

.p-tabview .p-tabview-nav-btn.p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .1rem #a6a1e1
}

.p-tabview .p-tabview-panels {
    background: #fff;
    padding: 1rem;
    border: 0 none;
    color: #383097;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px
}

.p-toolbar {
    background: #fff;
    border: 2px solid #f7f6fb;
    padding: 1rem;
    border-radius: 6px
}

.p-toolbar .p-toolbar-separator {
    margin: 0 .5rem
}

.p-confirm-popup {
    background: #fff;
    color: #383097;
    border: 0 none;
    border-radius: 6px;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, .2), 0px 24px 38px 3px rgba(0, 0, 0, .14), 0px 9px 46px 8px rgba(0, 0, 0, .12)
}

.p-confirm-popup .p-confirm-popup-content {
    padding: 1rem
}

.p-confirm-popup .p-confirm-popup-footer {
    text-align: right;
    padding: .5rem 1rem
}

.p-confirm-popup .p-confirm-popup-footer button {
    margin: 0 .5rem 0 0;
    width: auto
}

.p-confirm-popup .p-confirm-popup-footer button:last-child {
    margin: 0
}

.p-confirm-popup:after {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff
}

.p-confirm-popup:before {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff
}

.p-confirm-popup.p-confirm-popup-flipped:after {
    border-top-color: #fff
}

.p-confirm-popup.p-confirm-popup-flipped:before {
    border-top-color: #fff
}

.p-confirm-popup .p-confirm-popup-icon {
    font-size: 1.5rem
}

.p-confirm-popup .p-confirm-popup-message {
    margin-left: 1rem
}

.p-dialog {
    border-radius: 6px;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, .2), 0px 24px 38px 3px rgba(0, 0, 0, .14), 0px 9px 46px 8px rgba(0, 0, 0, .12);
    border: 0 none
}

.p-dialog .p-dialog-header {
    border-bottom: 0 none;
    background: #fff;
    color: #383097;
    padding: 1.5rem;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px
}

.p-dialog .p-dialog-header .p-dialog-title {
    font-weight: 100;
    font-size: 1.25rem
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    width: 2rem;
    height: 2rem;
    color: #7267b4;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color .3s, color .3s, box-shadow .3s;
    margin-right: .5rem
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    color: #383097;
    border-color: transparent;
    background: #f7f6fb
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
    margin-right: 0
}

.p-dialog .p-dialog-content {
    background: #fff;
    color: #383097;
    padding: 0 1.5rem 2rem 1.5rem
}

.p-dialog .p-dialog-footer {
    border-top: 0 none;
    background: #fff;
    color: #383097;
    padding: 0 1.5rem 1.5rem 1.5rem;
    text-align: right;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px
}

.p-dialog .p-dialog-footer button {
    margin: 0 .5rem 0 0;
    width: auto
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
    font-size: 2rem
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    margin-left: 1rem
}

.p-overlaypanel {
    background: #fff;
    color: #383097;
    border: 0 none;
    border-radius: 6px;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, .2), 0px 24px 38px 3px rgba(0, 0, 0, .14), 0px 9px 46px 8px rgba(0, 0, 0, .12)
}

.p-overlaypanel .p-overlaypanel-content {
    padding: 1rem
}

.p-overlaypanel .p-overlaypanel-close {
    background: #383097;
    color: #fff;
    width: 2rem;
    height: 2rem;
    transition: background-color .3s, color .3s, box-shadow .3s;
    border-radius: 50%;
    position: absolute;
    top: -1rem;
    right: -1rem
}

.p-overlaypanel .p-overlaypanel-close:enabled:hover {
    background: #352e8f;
    color: #fff
}

.p-overlaypanel:after {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff
}

.p-overlaypanel:before {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #f2f2f2
}

.p-overlaypanel.p-overlaypanel-flipped:after {
    border-top-color: #fff
}

.p-overlaypanel.p-overlaypanel-flipped:before {
    border-top-color: #fff
}

.p-sidebar {
    background: #fff;
    color: #383097;
    border: 0 none;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, .2), 0px 24px 38px 3px rgba(0, 0, 0, .14), 0px 9px 46px 8px rgba(0, 0, 0, .12)
}

.p-sidebar .p-sidebar-header {
    padding: 1rem
}

.p-sidebar .p-sidebar-header .p-sidebar-close, .p-sidebar .p-sidebar-header .p-sidebar-icon {
    width: 2rem;
    height: 2rem;
    color: #7267b4;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color .3s, color .3s, box-shadow .3s
}

.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover, .p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
    color: #383097;
    border-color: transparent;
    background: #f7f6fb
}

.p-sidebar .p-sidebar-header .p-sidebar-close:focus, .p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-sidebar .p-sidebar-header + .p-sidebar-content {
    padding-top: 0
}

.p-sidebar .p-sidebar-content {
    padding: 1rem
}

.p-tooltip .p-tooltip-text {
    background: #585858;
    color: #fff;
    padding: .5rem .75rem;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    border-radius: 6px
}

.p-tooltip.p-tooltip-right .p-tooltip-arrow {
    border-right-color: #585858
}

.p-tooltip.p-tooltip-left .p-tooltip-arrow {
    border-left-color: #585858
}

.p-tooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: #585858
}

.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: #585858
}

.p-fileupload .p-fileupload-buttonbar {
    background: #fff;
    padding: 1rem;
    border: 2px solid #f7f6fb;
    color: #383097;
    border-bottom: 0 none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px
}

.p-fileupload .p-fileupload-buttonbar .p-button {
    margin-right: .5rem
}

.p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-fileupload .p-fileupload-content {
    background: #fff;
    padding: 2rem 1rem;
    border: 2px solid #f7f6fb;
    color: #383097;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px
}

.p-fileupload .p-progressbar {
    height: .25rem
}

.p-fileupload .p-fileupload-row > div {
    padding: 1rem 1rem
}

.p-fileupload.p-fileupload-advanced .p-message {
    margin-top: 0
}

.p-fileupload-choose:not(.p-disabled):hover {
    background: #352e8f;
    color: #fff;
    border-color: #352e8f
}

.p-fileupload-choose:not(.p-disabled):active {
    background: #322b88;
    color: #fff;
    border-color: #322b88
}

.p-breadcrumb {
    background: #fff;
    border: 2px solid #f7f6fb;
    border-radius: 6px;
    padding: 1rem
}

.p-breadcrumb ul li .p-menuitem-link {
    transition: box-shadow .3s;
    border-radius: 6px
}

.p-breadcrumb ul li .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
    color: #383097
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
    color: #7267b4
}

.p-breadcrumb ul li.p-breadcrumb-chevron {
    margin: 0 .5rem 0 .5rem;
    color: #383097
}

.p-breadcrumb ul li:last-child .p-menuitem-text {
    color: #383097
}

.p-breadcrumb ul li:last-child .p-menuitem-icon {
    color: #7267b4
}

.p-contextmenu {
    padding: .5rem .5rem;
    background: #fff;
    color: #383097;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    border-radius: 6px;
    width: 12.5rem
}

.p-contextmenu .p-menuitem-link {
    padding: .75rem 1rem;
    color: #383097;
    border-radius: 6px;
    transition: box-shadow .3s;
    user-select: none
}

.p-contextmenu .p-menuitem-link .p-menuitem-text {
    color: #383097
}

.p-contextmenu .p-menuitem-link .p-menuitem-icon {
    color: #7267b4;
    margin-right: .5rem
}

.p-contextmenu .p-menuitem-link .p-submenu-icon {
    color: #7267b4
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
    background: #f7f6fb
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #383097
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #7267b4
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #7267b4
}

.p-contextmenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #a6a1e1
}

.p-contextmenu .p-submenu-list {
    padding: .5rem .5rem;
    background: #fff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    border-radius: 6px
}

.p-contextmenu .p-menuitem {
    margin: 0 0 4px 0
}

.p-contextmenu .p-menuitem:last-child {
    margin: 0
}

.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: #f7f6fb
}

.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: #383097
}

.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: #7267b4
}

.p-contextmenu .p-menu-separator {
    border-top: 1px solid #f7f6fb;
    margin: 4px 0
}

.p-contextmenu .p-submenu-icon {
    font-size: .875rem
}

.p-contextmenu .p-menuitem-badge {
    background: #383097;
    color: #fff;
    font-size: .75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 6px;
    margin-left: .5rem;
    padding-left: .5rem;
    padding-right: .5rem
}

.p-dock .p-dock-list {
    background: rgba(255, 255, 255, .1);
    border: 1px solid rgba(255, 255, 255, .2);
    padding: .5rem .5rem;
    border-radius: .5rem
}

.p-dock .p-dock-item {
    padding: .5rem
}

.p-dock .p-dock-action {
    width: 4rem;
    height: 4rem
}

.p-dock.p-dock-top .p-dock-item-second-prev, .p-dock.p-dock-top .p-dock-item-second-next, .p-dock.p-dock-bottom .p-dock-item-second-prev, .p-dock.p-dock-bottom .p-dock-item-second-next {
    margin: 0 .9rem
}

.p-dock.p-dock-top .p-dock-item-prev, .p-dock.p-dock-top .p-dock-item-next, .p-dock.p-dock-bottom .p-dock-item-prev, .p-dock.p-dock-bottom .p-dock-item-next {
    margin: 0 1.3rem
}

.p-dock.p-dock-top .p-dock-item-current, .p-dock.p-dock-bottom .p-dock-item-current {
    margin: 0 1.5rem
}

.p-dock.p-dock-left .p-dock-item-second-prev, .p-dock.p-dock-left .p-dock-item-second-next, .p-dock.p-dock-right .p-dock-item-second-prev, .p-dock.p-dock-right .p-dock-item-second-next {
    margin: .9rem 0
}

.p-dock.p-dock-left .p-dock-item-prev, .p-dock.p-dock-left .p-dock-item-next, .p-dock.p-dock-right .p-dock-item-prev, .p-dock.p-dock-right .p-dock-item-next {
    margin: 1.3rem 0
}

.p-dock.p-dock-left .p-dock-item-current, .p-dock.p-dock-right .p-dock-item-current {
    margin: 1.5rem 0
}

@media screen and (max-width: 960px) {
    .p-dock.p-dock-top .p-dock-list-container, .p-dock.p-dock-bottom .p-dock-list-container {
        overflow-x: auto;
        width: 100%
    }

    .p-dock.p-dock-top .p-dock-list-container .p-dock-list, .p-dock.p-dock-bottom .p-dock-list-container .p-dock-list {
        margin: 0 auto
    }

    .p-dock.p-dock-left .p-dock-list-container, .p-dock.p-dock-right .p-dock-list-container {
        overflow-y: auto;
        height: 100%
    }

    .p-dock.p-dock-left .p-dock-list-container .p-dock-list, .p-dock.p-dock-right .p-dock-list-container .p-dock-list {
        margin: auto 0
    }

    .p-dock .p-dock-list .p-dock-item {
        transform: none;
        margin: 0
    }
}

.p-megamenu {
    padding: .5rem;
    background: #f3f2f7;
    color: #383097;
    border: 2px solid #f7f6fb;
    border-radius: 6px
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
    padding: .75rem 1rem;
    color: #383097;
    border-radius: 6px;
    transition: box-shadow .3s;
    user-select: none
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: #383097
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #7267b4;
    margin-right: .5rem
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: #7267b4;
    margin-left: .5rem
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: #f7f6fb
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #383097
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #7267b4
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #7267b4
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #a6a1e1
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link, .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
    background: #f7f6fb
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text, .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #383097
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #7267b4
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon, .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #7267b4
}

.p-megamenu .p-menuitem-link {
    padding: .75rem 1rem;
    color: #383097;
    border-radius: 6px;
    transition: box-shadow .3s;
    user-select: none
}

.p-megamenu .p-menuitem-link .p-menuitem-text {
    color: #383097
}

.p-megamenu .p-menuitem-link .p-menuitem-icon {
    color: #7267b4;
    margin-right: .5rem
}

.p-megamenu .p-menuitem-link .p-submenu-icon {
    color: #7267b4
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
    background: #f7f6fb
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #383097
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #7267b4
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #7267b4
}

.p-megamenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #a6a1e1
}

.p-megamenu .p-megamenu-panel {
    background: #fff;
    color: #383097;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.p-megamenu .p-megamenu-submenu-header {
    margin: 0;
    padding: .75rem 1rem;
    color: #383097;
    background: #fff;
    font-weight: 100;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px
}

.p-megamenu .p-megamenu-submenu {
    padding: .5rem .5rem;
    width: 12.5rem
}

.p-megamenu .p-megamenu-submenu .p-menu-separator {
    border-top: 1px solid #f7f6fb;
    margin: 4px 0
}

.p-megamenu .p-megamenu-submenu .p-menuitem {
    margin: 0 0 4px 0
}

.p-megamenu .p-megamenu-submenu .p-menuitem:last-child {
    margin: 0
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: #f7f6fb
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: #383097
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: #7267b4
}

.p-megamenu.p-megamenu-vertical {
    width: 12.5rem;
    padding: .5rem .5rem
}

.p-megamenu.p-megamenu-vertical .p-menuitem {
    margin: 0 0 4px 0
}

.p-megamenu.p-megamenu-vertical .p-menuitem:last-child {
    margin: 0
}

.p-megamenu .p-menuitem-badge {
    background: #383097;
    color: #fff;
    font-size: .75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 6px;
    margin-left: .5rem;
    padding-left: .5rem;
    padding-right: .5rem
}

.p-menu {
    padding: .5rem .5rem;
    background: #fff;
    color: #383097;
    border: 2px solid #f7f6fb;
    border-radius: 6px;
    width: 12.5rem
}

.p-menu .p-menuitem-link {
    padding: .75rem 1rem;
    color: #383097;
    border-radius: 6px;
    transition: box-shadow .3s;
    user-select: none
}

.p-menu .p-menuitem-link .p-menuitem-text {
    color: #383097
}

.p-menu .p-menuitem-link .p-menuitem-icon {
    color: #7267b4;
    margin-right: .5rem
}

.p-menu .p-menuitem-link .p-submenu-icon {
    color: #7267b4
}

.p-menu .p-menuitem-link:not(.p-disabled):hover {
    background: #f7f6fb
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #383097
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #7267b4
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #7267b4
}

.p-menu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #a6a1e1
}

.p-menu.p-menu-overlay {
    background: #fff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.p-menu .p-submenu-header {
    margin: 0;
    padding: .75rem 1rem;
    color: #383097;
    background: #fff;
    font-weight: 100;
    border-top-right-radius: 0;
    border-top-left-radius: 0
}

.p-menu .p-menu-separator {
    border-top: 1px solid #f7f6fb;
    margin: 4px 0
}

.p-menu .p-menuitem {
    margin: 0 0 4px 0
}

.p-menu .p-menuitem:last-child {
    margin: 0
}

.p-menu .p-menuitem-badge {
    background: #383097;
    color: #fff;
    font-size: .75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 6px;
    margin-left: .5rem;
    padding-left: .5rem;
    padding-right: .5rem
}

.p-menubar {
    padding: .5rem;
    background: #f3f2f7;
    color: #383097;
    border: 2px solid #f7f6fb;
    border-radius: 6px
}

.p-menubar .p-menuitem-link {
    padding: .75rem 1rem;
    color: #383097;
    border-radius: 6px;
    transition: box-shadow .3s;
    user-select: none
}

.p-menubar .p-menuitem-link .p-menuitem-text {
    color: #383097
}

.p-menubar .p-menuitem-link .p-menuitem-icon {
    color: #7267b4;
    margin-right: .5rem
}

.p-menubar .p-menuitem-link .p-submenu-icon {
    color: #7267b4
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover {
    background: #f7f6fb
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #383097
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #7267b4
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #7267b4
}

.p-menubar .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #a6a1e1
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: .75rem 1rem;
    color: #383097;
    border-radius: 6px;
    transition: box-shadow .3s;
    user-select: none
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: #383097
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #7267b4;
    margin-right: .5rem
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: #7267b4;
    margin-left: .5rem
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: #f7f6fb
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #383097
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #7267b4
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #7267b4
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #a6a1e1
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link, .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
    background: #f7f6fb
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text, .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #383097
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #7267b4
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon, .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #7267b4
}

.p-menubar .p-submenu-list {
    padding: .5rem .5rem;
    background: #fff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    width: 12.5rem
}

.p-menubar .p-submenu-list .p-menu-separator {
    border-top: 1px solid #f7f6fb;
    margin: 4px 0
}

.p-menubar .p-submenu-list .p-submenu-icon {
    font-size: .875rem
}

.p-menubar .p-submenu-list .p-menuitem {
    margin: 0 0 4px 0
}

.p-menubar .p-submenu-list .p-menuitem:last-child {
    margin: 0
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: #f7f6fb
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: #383097
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: #7267b4
}

.p-menubar .p-menuitem-badge {
    background: #383097;
    color: #fff;
    font-size: .75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 6px;
    margin-left: .5rem;
    padding-left: .5rem;
    padding-right: .5rem
}

@media screen and (max-width: 960px) {
    .p-menubar {
        position: relative
    }

    .p-menubar .p-menubar-button {
        display: flex;
        width: 2rem;
        height: 2rem;
        color: #7267b4;
        border-radius: 50%;
        transition: background-color .3s, color .3s, box-shadow .3s
    }

    .p-menubar .p-menubar-button:hover {
        color: #7267b4;
        background: #f7f6fb
    }

    .p-menubar .p-menubar-button:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 .1rem #a6a1e1
    }

    .p-menubar .p-menubar-root-list {
        position: absolute;
        display: none;
        padding: .5rem .5rem;
        background: #fff;
        border: 0 none;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
        width: 100%
    }

    .p-menubar .p-menubar-root-list .p-menu-separator {
        border-top: 1px solid #f7f6fb;
        margin: 4px 0
    }

    .p-menubar .p-menubar-root-list .p-submenu-icon {
        font-size: .875rem
    }

    .p-menubar .p-menubar-root-list > .p-menuitem {
        width: 100%;
        position: static
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
        padding: .75rem 1rem;
        color: #383097;
        border-radius: 6px;
        transition: box-shadow .3s;
        user-select: none
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
        color: #383097
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
        color: #7267b4;
        margin-right: .5rem
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
        color: #7267b4
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
        background: #f7f6fb
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
        color: #383097
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
        color: #7267b4
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
        color: #7267b4
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 .15rem #a6a1e1
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
        margin-left: auto;
        transition: transform .3s
    }

    .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
        transform: rotate(-180deg)
    }

    .p-menubar .p-menubar-root-list .p-submenu-list {
        width: 100%;
        position: static;
        box-shadow: none;
        border: 0 none
    }

    .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
        transition: transform .3s;
        transform: rotate(90deg)
    }

    .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
        transform: rotate(-90deg)
    }

    .p-menubar .p-menubar-root-list .p-menuitem {
        width: 100%;
        position: static
    }

    .p-menubar .p-menubar-root-list ul li a {
        padding-left: 2.25rem
    }

    .p-menubar .p-menubar-root-list ul li ul li a {
        padding-left: 3.75rem
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li a {
        padding-left: 5.25rem
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
        padding-left: 6.75rem
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
        padding-left: 8.25rem
    }

    .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
        display: flex;
        flex-direction: column;
        top: 100%;
        left: 0;
        z-index: 1
    }
}

.p-panelmenu .p-panelmenu-header > a {
    padding: 1rem;
    border: 2px solid #f7f6fb;
    color: #383097;
    background: #fff;
    font-weight: 100;
    border-radius: 6px;
    transition: box-shadow .3s
}

.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
    margin-right: .5rem
}

.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
    margin-right: .5rem
}

.p-panelmenu .p-panelmenu-header > a:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
    background: #f7f6fb;
    border-color: #f7f6fb;
    color: #383097
}

.p-panelmenu .p-panelmenu-header.p-highlight {
    margin-bottom: 0
}

.p-panelmenu .p-panelmenu-header.p-highlight > a {
    background: #fff;
    border-color: #f7f6fb;
    color: #383097;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
    border-color: #f7f6fb;
    background: #f7f6fb;
    color: #383097
}

.p-panelmenu .p-panelmenu-content {
    padding: .5rem .5rem;
    border: 2px solid #f7f6fb;
    background: #fff;
    color: #383097;
    margin-bottom: 0;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
    padding: .75rem 1rem;
    color: #383097;
    border-radius: 6px;
    transition: box-shadow .3s;
    user-select: none
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
    color: #383097
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
    color: #7267b4;
    margin-right: .5rem
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
    color: #7267b4
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
    background: #f7f6fb
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #383097
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #7267b4
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #7267b4
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #a6a1e1
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
    margin-right: .5rem
}

.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
    padding: 0 0 0 1rem
}

.p-panelmenu .p-panelmenu-panel {
    margin-bottom: 0
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a {
    border-radius: 0
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
    border-radius: 0
}

.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
    border-top: 0 none
}

.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover > a, .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover > a {
    border-top: 0 none
}

.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px
}

.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight) > a {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px
}

.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px
}

.p-panelmenu .p-menuitem-badge {
    background: #383097;
    color: #fff;
    font-size: .75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 6px;
    margin-left: .5rem;
    padding-left: .5rem;
    padding-right: .5rem
}

.p-slidemenu {
    padding: .5rem .5rem;
    background: #fff;
    color: #383097;
    border: 2px solid #f7f6fb;
    border-radius: 6px;
    width: 12.5rem
}

.p-slidemenu .p-menuitem-link {
    padding: .75rem 1rem;
    color: #383097;
    border-radius: 6px;
    transition: box-shadow .3s;
    user-select: none
}

.p-slidemenu .p-menuitem-link .p-menuitem-text {
    color: #383097
}

.p-slidemenu .p-menuitem-link .p-menuitem-icon {
    color: #7267b4;
    margin-right: .5rem
}

.p-slidemenu .p-menuitem-link .p-submenu-icon {
    color: #7267b4
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
    background: #f7f6fb
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #383097
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #7267b4
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #7267b4
}

.p-slidemenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #a6a1e1
}

.p-slidemenu.p-slidemenu-overlay {
    background: #fff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.p-slidemenu .p-slidemenu-list {
    padding: .5rem .5rem;
    background: #fff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
    background: #f7f6fb
}

.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-text {
    color: #383097
}

.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon, .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon {
    color: #7267b4
}

.p-slidemenu .p-slidemenu-separator {
    border-top: 1px solid #f7f6fb;
    margin: 4px 0
}

.p-slidemenu .p-slidemenu-icon {
    font-size: .875rem
}

.p-slidemenu .p-slidemenu-backward {
    padding: .75rem 1rem;
    color: #383097
}

.p-slidemenu .p-menuitem-badge {
    background: #383097;
    color: #fff;
    font-size: .75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 6px;
    margin-left: .5rem;
    padding-left: .5rem;
    padding-right: .5rem
}

.p-steps .p-steps-item .p-menuitem-link {
    background: transparent;
    transition: box-shadow .3s;
    border-radius: 6px;
    background: #fff
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    color: #383097;
    border: 2px solid #f7f6fb;
    background: #fff;
    min-width: 2rem;
    height: 2rem;
    line-height: 2rem;
    font-size: 1.143rem;
    z-index: 1;
    border-radius: 50%
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    margin-top: .5rem;
    color: #7267b4
}

.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
    background: #383097;
    color: #fff
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
    font-weight: 100;
    color: #383097
}

.p-steps .p-steps-item:before {
    content: " ";
    border-top: 1px solid #f7f6fb;
    width: 100%;
    top: 50%;
    left: 0;
    display: block;
    position: absolute;
    margin-top: -1rem
}

.p-tabmenu .p-tabmenu-nav {
    background: #fff;
    border: 1px solid #f7f6fb;
    border-width: 0 0 2px 0
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    margin-right: 0
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    border: solid #f7f6fb;
    border-width: 0 0 2px 0;
    border-color: transparent transparent #f7f6fb transparent;
    background: #fff;
    color: #7267b4;
    padding: 1rem;
    font-weight: 100;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    transition: box-shadow .3s;
    margin: 0 0 -2px 0
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
    margin-right: .5rem
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .1rem #a6a1e1
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
    background: #fff;
    border-color: #383097;
    color: #7267b4
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background: #fff;
    border-color: #383097;
    color: #383097
}

.p-tabmenu .p-tabmenu-left-icon {
    margin-right: .5rem
}

.p-tabmenu .p-tabmenu-right-icon {
    margin-left: .5rem
}

.p-tabmenu .p-tabmenu-nav-btn.p-link {
    background: #fff;
    color: #383097;
    width: 2.857rem;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
    border-radius: 0
}

.p-tabmenu .p-tabmenu-nav-btn.p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .1rem #a6a1e1
}

.p-tieredmenu {
    padding: .5rem .5rem;
    background: #fff;
    color: #383097;
    border: 2px solid #f7f6fb;
    border-radius: 6px;
    width: 12.5rem
}

.p-tieredmenu .p-menuitem-link {
    padding: .75rem 1rem;
    color: #383097;
    border-radius: 6px;
    transition: box-shadow .3s;
    user-select: none
}

.p-tieredmenu .p-menuitem-link .p-menuitem-text {
    color: #383097
}

.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
    color: #7267b4;
    margin-right: .5rem
}

.p-tieredmenu .p-menuitem-link .p-submenu-icon {
    color: #7267b4
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
    background: #f7f6fb
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #383097
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #7267b4
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #7267b4
}

.p-tieredmenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #a6a1e1
}

.p-tieredmenu.p-tieredmenu-overlay {
    background: #fff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.p-tieredmenu .p-submenu-list {
    padding: .5rem .5rem;
    background: #fff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.p-tieredmenu .p-menuitem {
    margin: 0 0 4px 0
}

.p-tieredmenu .p-menuitem:last-child {
    margin: 0
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: #f7f6fb
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: #383097
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: #7267b4
}

.p-tieredmenu .p-menu-separator {
    border-top: 1px solid #f7f6fb;
    margin: 4px 0
}

.p-tieredmenu .p-submenu-icon {
    font-size: .875rem
}

.p-tieredmenu .p-menuitem-badge {
    background: #383097;
    color: #fff;
    font-size: .75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 6px;
    margin-left: .5rem;
    padding-left: .5rem;
    padding-right: .5rem
}

.p-inline-message {
    padding: .5rem .75rem;
    margin: 0;
    border-radius: 6px
}

.p-inline-message.p-inline-message-info {
    background: #e1f2f7;
    border: 4px solid #83c7e0;
    border-width: 2px;
    color: #585858
}

.p-inline-message.p-inline-message-info .p-inline-message-icon {
    color: #585858
}

.p-inline-message.p-inline-message-success {
    background: #f2f8e1;
    border: 4px solid #c7e084;
    border-width: 2px;
    color: #585858
}

.p-inline-message.p-inline-message-success .p-inline-message-icon {
    color: #585858
}

.p-inline-message.p-inline-message-warn {
    background: #ffecdb;
    border: 4px solid #ffb065;
    border-width: 2px;
    color: #585858
}

.p-inline-message.p-inline-message-warn .p-inline-message-icon {
    color: #585858
}

.p-inline-message.p-inline-message-error {
    background: #f7e1e6;
    border: 4px solid #de8499;
    border-width: 2px;
    color: #585858
}

.p-inline-message.p-inline-message-error .p-inline-message-icon {
    color: #585858
}

.p-inline-message .p-inline-message-icon {
    font-size: 1rem;
    margin-right: .5rem
}

.p-inline-message .p-inline-message-text {
    font-size: 1rem
}

.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
    margin-right: 0
}

.p-message {
    margin: 1rem 0;
    border-radius: 6px
}

.p-message .p-message-wrapper {
    padding: 1rem 1.5rem
}

.p-message .p-message-close {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: transparent;
    transition: background-color .3s, color .3s, box-shadow .3s
}

.p-message .p-message-close:hover {
    background: rgba(255, 255, 255, .3)
}

.p-message .p-message-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-message.p-message-info {
    background: #e1f2f7;
    border: 4px solid #83c7e0;
    border-width: 0 0 0 6px;
    color: #585858
}

.p-message.p-message-info .p-message-icon {
    color: #585858
}

.p-message.p-message-info .p-message-close {
    color: #585858
}

.p-message.p-message-success {
    background: #f2f8e1;
    border: 4px solid #c7e084;
    border-width: 0 0 0 6px;
    color: #585858
}

.p-message.p-message-success .p-message-icon {
    color: #585858
}

.p-message.p-message-success .p-message-close {
    color: #585858
}

.p-message.p-message-warn {
    background: #ffecdb;
    border: 4px solid #ffb065;
    border-width: 0 0 0 6px;
    color: #585858
}

.p-message.p-message-warn .p-message-icon {
    color: #585858
}

.p-message.p-message-warn .p-message-close {
    color: #585858
}

.p-message.p-message-error {
    background: #f7e1e6;
    border: 4px solid #de8499;
    border-width: 0 0 0 6px;
    color: #585858
}

.p-message.p-message-error .p-message-icon {
    color: #585858
}

.p-message.p-message-error .p-message-close {
    color: #585858
}

.p-message .p-message-text {
    font-size: 1rem;
    font-weight: 400
}

.p-message .p-message-icon {
    font-size: 1.5rem;
    margin-right: .5rem
}

.p-message .p-message-summary {
    font-weight: 700
}

.p-message .p-message-detail {
    margin-left: .5rem
}

.p-toast {
    opacity: .9
}

.p-toast .p-toast-message {
    margin: 0 0 1rem 0;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    border-radius: 6px
}

.p-toast .p-toast-message .p-toast-message-content {
    padding: 1rem;
    border-width: 0 0 0 6px
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
    margin: 0 0 0 1rem
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
    font-size: 2rem
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
    font-weight: 100
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
    margin: .5rem 0 0 0
}

.p-toast .p-toast-message .p-toast-icon-close {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: transparent;
    transition: background-color .3s, color .3s, box-shadow .3s
}

.p-toast .p-toast-message .p-toast-icon-close:hover {
    background: rgba(255, 255, 255, .3)
}

.p-toast .p-toast-message .p-toast-icon-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-toast .p-toast-message.p-toast-message-info {
    background: #e1f2f7;
    border: 4px solid #83c7e0;
    border-width: 0 0 0 6px;
    color: #585858
}

.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
    color: #585858
}

.p-toast .p-toast-message.p-toast-message-success {
    background: #f2f8e1;
    border: 4px solid #c7e084;
    border-width: 0 0 0 6px;
    color: #585858
}

.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
    color: #585858
}

.p-toast .p-toast-message.p-toast-message-warn {
    background: #ffecdb;
    border: 4px solid #ffb065;
    border-width: 0 0 0 6px;
    color: #585858
}

.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
    color: #585858
}

.p-toast .p-toast-message.p-toast-message-error {
    background: #f7e1e6;
    border: 4px solid #de8499;
    border-width: 0 0 0 6px;
    color: #585858
}

.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
    color: #585858
}

.p-galleria .p-galleria-close {
    margin: .5rem;
    background: transparent;
    color: #f3f2f7;
    width: 4rem;
    height: 4rem;
    transition: background-color .3s, color .3s, box-shadow .3s;
    border-radius: 50%
}

.p-galleria .p-galleria-close .p-galleria-close-icon {
    font-size: 2rem
}

.p-galleria .p-galleria-close:hover {
    background: rgba(255, 255, 255, .1);
    color: #f3f2f7
}

.p-galleria .p-galleria-item-nav {
    background: transparent;
    color: #f3f2f7;
    width: 4rem;
    height: 4rem;
    transition: background-color .3s, color .3s, box-shadow .3s;
    border-radius: 6px;
    margin: 0 .5rem
}

.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon, .p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
    font-size: 2rem
}

.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
    background: rgba(255, 255, 255, .1);
    color: #f3f2f7
}

.p-galleria .p-galleria-caption {
    background: rgba(0, 0, 0, .5);
    color: #f3f2f7;
    padding: 1rem
}

.p-galleria .p-galleria-indicators {
    padding: 1rem
}

.p-galleria .p-galleria-indicators .p-galleria-indicator button {
    background-color: #f7f6fb;
    width: 1rem;
    height: 1rem;
    transition: background-color .3s, color .3s, box-shadow .3s;
    border-radius: 50%
}

.p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
    background: #f7f6fb
}

.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: #383097;
    color: #fff
}

.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator, .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
    margin-right: .5rem
}

.p-galleria.p-galleria-indicators-left .p-galleria-indicator, .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
    margin-bottom: .5rem
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
    background: rgba(0, 0, 0, .5)
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
    background: rgba(255, 255, 255, .4)
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
    background: rgba(255, 255, 255, .6)
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: #383097;
    color: #fff
}

.p-galleria .p-galleria-thumbnail-container {
    background: rgba(0, 0, 0, .9);
    padding: 1rem .25rem
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev, .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
    margin: .5rem;
    background-color: transparent;
    color: #f3f2f7;
    width: 2rem;
    height: 2rem;
    transition: background-color .3s, color .3s, box-shadow .3s;
    border-radius: 50%
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover, .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
    background: rgba(255, 255, 255, .1);
    color: #f3f2f7
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-galleria-mask {
    --maskbg: rgba(0, 0, 0, 0.9)
}

.p-image-mask {
    --maskbg: rgba(0, 0, 0, 0.9)
}

.p-image-preview-indicator {
    background-color: transparent;
    color: #f8f9fa;
    transition: background-color .3s, color .3s, box-shadow .3s
}

.p-image-preview-container:hover > .p-image-preview-indicator {
    background-color: rgba(0, 0, 0, .5)
}

.p-image-toolbar {
    padding: 1rem
}

.p-image-action.p-link {
    color: #f8f9fa;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    transition: background-color .3s, color .3s, box-shadow .3s;
    margin-right: .5rem
}

.p-image-action.p-link:last-child {
    margin-right: 0
}

.p-image-action.p-link:hover {
    color: #f8f9fa;
    background-color: rgba(255, 255, 255, .1)
}

.p-image-action.p-link i {
    font-size: 1.5rem
}

.p-avatar {
    background-color: #f7f6fb;
    border-radius: 6px
}

.p-avatar.p-avatar-lg {
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem
}

.p-avatar.p-avatar-lg .p-avatar-icon {
    font-size: 1.5rem
}

.p-avatar.p-avatar-xl {
    width: 4rem;
    height: 4rem;
    font-size: 2rem
}

.p-avatar.p-avatar-xl .p-avatar-icon {
    font-size: 2rem
}

.p-avatar-group .p-avatar {
    border: 2px solid #fff
}

.p-badge {
    background: #383097;
    color: #fff;
    font-size: .75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem
}

.p-badge.p-badge-secondary {
    background-color: #dd3636;
    color: #fff
}

.p-badge.p-badge-success {
    background-color: #3aa76d;
    color: #fff
}

.p-badge.p-badge-info {
    background-color: #276ef1;
    color: #fff
}

.p-badge.p-badge-warning {
    background-color: #ff922a;
    color: #fff
}

.p-badge.p-badge-danger {
    background-color: #dd3636;
    color: #fff
}

.p-badge.p-badge-lg {
    font-size: 1.125rem;
    min-width: 2.25rem;
    height: 2.25rem;
    line-height: 2.25rem
}

.p-badge.p-badge-xl {
    font-size: 1.5rem;
    min-width: 3rem;
    height: 3rem;
    line-height: 3rem
}

.p-chip {
    background-color: #f7f6fb;
    color: #383097;
    border-radius: 16px;
    padding: 0 .75rem
}

.p-chip .p-chip-text {
    line-height: 1.5;
    margin-top: .25rem;
    margin-bottom: .25rem
}

.p-chip .p-chip-icon {
    margin-right: .5rem
}

.p-chip .pi-chip-remove-icon {
    margin-left: .5rem
}

.p-chip img {
    width: 2rem;
    height: 2rem;
    margin-left: -0.75rem;
    margin-right: .5rem
}

.p-chip .pi-chip-remove-icon {
    border-radius: 6px;
    transition: background-color .3s, color .3s, box-shadow .3s
}

.p-chip .pi-chip-remove-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-inplace .p-inplace-display {
    padding: .5rem .75rem;
    border-radius: 6px;
    transition: background-color .3s, color .3s, border-color .3s, box-shadow .3s
}

.p-inplace .p-inplace-display:not(.p-disabled):hover {
    background: #f7f6fb;
    color: #383097
}

.p-inplace .p-inplace-display:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .1rem #a6a1e1
}

.p-progressbar {
    border: 0 none;
    height: 1.5rem;
    background: #f7f6fb;
    border-radius: 6px
}

.p-progressbar .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: #383097
}

.p-progressbar .p-progressbar-label {
    color: #fff;
    line-height: 1.5rem
}

.p-scrolltop {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    transition: background-color .3s, color .3s, box-shadow .3s
}

.p-scrolltop.p-link {
    background: rgba(0, 0, 0, .7)
}

.p-scrolltop.p-link:hover {
    background: rgba(0, 0, 0, .8)
}

.p-scrolltop .p-scrolltop-icon {
    font-size: 1.5rem;
    color: #f3f2f7
}

.p-skeleton {
    background-color: #f7f6fb;
    border-radius: 6px
}

.p-skeleton:after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0))
}

.p-tag {
    background: #383097;
    color: #fff;
    font-size: .75rem;
    font-weight: 700;
    padding: .25rem .4rem;
    border-radius: 6px
}

.p-tag.p-tag-success {
    background-color: #3aa76d;
    color: #fff
}

.p-tag.p-tag-info {
    background-color: #276ef1;
    color: #fff
}

.p-tag.p-tag-warning {
    background-color: #ff922a;
    color: #fff
}

.p-tag.p-tag-danger {
    background-color: #dd3636;
    color: #fff
}

.p-tag .p-tag-icon {
    margin-right: .25rem;
    font-size: .75rem
}

.p-terminal {
    background: #fff;
    color: #383097;
    border: 2px solid #f7f6fb;
    padding: 1rem
}

.p-terminal .p-terminal-input {
    font-size: 1rem;
    font-family: CeraPro-Regular
}

.p-button .p-button-label {
    font-weight: 100
}

.p-buttonset .p-button-label, .p-togglebutton .p-button-label {
    font-weight: 400
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background-color: #383097
}

.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background-color: #383097
}

.p-panel {
    border: 2px solid #f7f6fb;
    border-radius: 6px
}

.p-panel .p-panel-header {
    border: 0 none
}

.p-panel .p-panel-content {
    border: 0 none
}

.p-fieldset .p-fieldset-legend {
    border-color: transparent
}

.p-accordion .p-accordion-toggle-icon {
    order: 10;
    margin-left: auto
}

.p-accordion .p-accordion-toggle-icon.pi-chevron-right::before {
    content: ""
}

.p-accordion .p-accordion-toggle-icon.pi-chevron-down::before {
    content: ""
}

.p-accordion .p-accordion-header.p-highlight .p-accordion-header-link {
    padding-bottom: calc(1rem + 2px);
    border-bottom: 0 none
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    border-bottom: 0 none
}

.p-inline-message.p-inline-message-info {
    border-color: #e1f2f7
}

.p-inline-message.p-inline-message-success {
    border-color: #f2f8e1
}

.p-inline-message.p-inline-message-warn {
    border-color: #ffecdb
}

.p-inline-message.p-inline-message-error {
    border-color: #f7e1e6
}

.p-inputtext:enabled:focus {
    box-shadow: none
}

.p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none
}

.p-multiselect:not(.p-disabled).p-focus {
    box-shadow: none
}

.p-cascadeselect:not(.p-disabled).p-focus {
    box-shadow: none
}

.p-autocomplete.p-autocomplete-multiple:not(.p-disabled).p-focus {
    box-shadow: none
}

.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
    box-shadow: none
}

.p-orderlist .p-orderlist-list {
    border-top: 0 none
}

.p-picklist .p-picklist-list {
    border-top: 0 none
}

.p-panelmenu .p-panelmenu-icon.pi-chevron-right, .p-panelmenu .p-panelmenu-icon.pi-chevron-down {
    order: 10;
    margin-left: auto
}

.p-panelmenu .p-panelmenu-icon.pi-chevron-right::before {
    content: ""
}

.p-panelmenu .p-panelmenu-icon.pi-chevron-down::before {
    content: ""
}

.p-panelmenu .p-panelmenu-header.p-highlight > a {
    padding-bottom: calc(1rem + 2px);
    border-bottom: 0 none
}

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
    padding-bottom: calc(1rem + 2px);
    border-bottom: 0 none
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
    box-shadow: inset 0 2px 0 0 #383097
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
    box-shadow: inset 0 -2px 0 0 #383097
}
