/* You can add global styles to this file, and also import other style files */
@use "./assets/themes/ernestto/theme.css";
@use "./assets/themes/ernestto/fonts.css";
@use "./assets/themes/ernestto/dimens.scss";
@use "./assets/themes/ernestto/colors.scss";


$primary_color: colors.$primary_color;
$primary_light_color: colors.$primary_light_color;
$primary_text_color: colors.$primary_text_color;
$secondary_text_color: colors.$secondary_text_color;
$secondary_color: colors.$secondary_color;
$success_color: colors.$success_color;
$error_color: colors.$error_color;
$warning_color: colors.$warning_color;
$highlight_color: colors.$highlight_color;
$highlight_lighter_color: colors.$highlight_lighter_color;

$border_radius: dimens.$border_radius;
$font_size_small: dimens.$font_size_small;
$font_size_normal: dimens.$font_size_normal;
$font_size_medium: dimens.$font_size_medium;
$font_size_large: dimens.$font_size_large;
$font_size_super: dimens.$font_size_super;

h1{
  font-family: CeraPro-Black, serif;
  font-size: dimens.$font_size_h1;
  color: colors.$primary_color;
  letter-spacing: dimens.$font_letter_spacing_wider;
  line-height: dimens.$heading_line_height;
}

h2{
  font-family: CeraPro-Bold, serif;
  font-size: dimens.$font_size_h2;
  color: colors.$primary_color;
  letter-spacing: dimens.$font_letter_spacing;
  line-height: dimens.$heading_line_height;
}

h3{
  font-family: CeraPro-Regular, serif;
  font-size: dimens.$font_size_h3;
  color: colors.$primary_color;
  letter-spacing: dimens.$font_letter_spacing;
  line-height: dimens.$heading_line_height;
  font-weight: 700;
}

h4{
  font-family: CeraPro-Bold, serif;
  font-size: dimens.$font_size_medium;
  color: colors.$primary_color;
  letter-spacing: dimens.$font_letter_spacing_wider;
}

h5{
  font-family: CeraPro-Regular, serif;
  font-size: dimens.$font_size_medium;
  color: colors.$primary_color;
  letter-spacing: dimens.$font_letter_spacing_wider;
}

p{
  font-family: CeraPro-Regular, serif;
  font-size: dimens.$font_size_normal;
  color: colors.$primary_color;
  letter-spacing: dimens.$font_letter_spacing_wider;
  margin: 0;
}

.inline-error{
  font-family: CeraPro-Bold, serif;
  font-size: dimens.$font_size_normal;
  color: colors.$error_color;
  letter-spacing: dimens.$font_letter_spacing_wider;
  margin: 0;
}

.big-emoji{
  font-family: CeraPro-Bold, serif;
  font-size: dimens.$font_size_super_card;
  margin: 0;
}

html, body{
  margin: 0;
  max-width: 100%;
  overflow-x: hidden;
}

.column-left{
  display:flex;
  width:0;
  flex-grow:1;
  justify-content: start;
}

.column-center{
  display:flex;
  width:0;
  flex-grow:1;
  justify-content: center;
}

.column-right{
  display:flex;
  width:0;
  flex-grow:1;
  justify-content: end;
}

.icon-TIRE_SERVICE {
  background-image: url('/assets/images/types/tire_service.svg');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
  margin:4px;
  color: $primary_color;
}

.icon-CAR_SERVICE {
  background-image: url('/assets/images/types/car_service.svg');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
  margin:4px;
  color: $primary_color;
}

.icon-MOT {
  background-image: url('/assets/images/types/mot.svg');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
  margin:4px;
  color: $primary_color;
}

.vehicle-icon {
  background-image: url('/assets/images/icons/vehicle.svg');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
  margin:4px;
  color: $primary_color;
}

.job-icon {
  background-image: url('/assets/images/icons/job.svg');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
  margin:4px;
  color: $primary_color;
}

.date-icon {
  background-image: url('/assets/images/icons/date.svg');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
  margin:4px;
  color: $primary_color;
}

.time-icon {
  background-image: url('/assets/images/icons/time.svg');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
  margin:4px;
  color: $primary_color;
}

.destination-icon {
  background-image: url('/assets/images/icons/destination.svg');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
  margin:4px;
  color: $primary_color;
}

.font-icon {
  color: $primary_color;
}
