$primary_color: #383097;
$primary_light_color: #7267B4;
$primary_text_color: #383097;
$secondary_text_color: #7267B4;
$secondary_color: #DD3636;
$success_color: #3AA76D;
$error_color: #DD3636;
$warning_color: #FF922A;
$highlight_color: #EEEDF2;
$highlight_lighter_color: #F5F5FA;
